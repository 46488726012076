<template>
  <div style="width: 100%;">
    <div v-if="!hasH5" class="foot">
      <span>© 2020 北京容智科技发展有限公司 京ICP备19036872号-3 All rights reserved. 京公网安备</span>
      <span>联系邮箱：info@wiseivy.com.cn联系电话：010-80755328</span>
    </div>
    <div v-if="hasH5">
      <div class="main-foot">
        <div>
          © 2020 北京容智科技发展有限公司 京ICP备19036872号-3 All
          rights reserved. 京公网安备 11011402010815号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return{
      hasH5: false,
    }
  },
  created() {
    this.hasH5 = this.isMobile()
  },
  methods: {
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
  }
}
</script>

<style lang="less" scoped>
.main-foot {
  height: 149px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 768px;

  div {
    width: 667px;
    height: 64px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00233E;
    line-height: 36px;
  }
}
.foot {
  background: #3E6D77;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 133px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dddddd;

  span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E9EEFF;
    line-height: 26px;
  }
}
</style>