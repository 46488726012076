import {createRouter, createWebHistory, createMemoryHistory} from 'vue-router'
import DocTrans from '../views/DocTrans.vue'
import Home from '../views/Home.vue'
import PolishTrans from '../views/PolishTrans.vue'
import Proto from '../views/Proto.vue'

const routes = [
    {
        name: "TransProPrice",
        path: "/transProPrice",
        component: () => import('../views/TransProPrice')
    },
    {
        name: "TransProTime",
        path: "/transProTime",
        component: () => import('../views/TransProTime')
    },
    {
        path: '/',
        redirect: "/index"
    },
    {
        path: '/index',
        name: 'Home',
        component: Home
    },
    {
        path: '/trans-doc',
        name: 'DocTrans',
        component: DocTrans
    },
    {
        path: '/trans-polish',
        name: 'PolishTrans',
        component: PolishTrans
    },
    {
        path: '/trans-pro-proto',
        name: 'Proto',
        component: Proto
    },
]

const router = createRouter({
    history: createWebHistory('trans-pro'),
    routes
})

export default router
