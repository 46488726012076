/**
 * @fileoverview gRPC-Web generated client stub for Auth
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.Auth = require('./authService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Auth.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Auth.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.LoginRequest,
 *   !proto.Auth.LoginResponse>}
 */
const methodDescriptor_AuthService_userLogin = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/userLogin',
  grpc.web.MethodType.UNARY,
  proto.Auth.LoginRequest,
  proto.Auth.LoginResponse,
  /**
   * @param {!proto.Auth.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.userLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/userLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_userLogin,
      callback);
};


/**
 * @param {!proto.Auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.userLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/userLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_userLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.OutRequest,
 *   !proto.Auth.OutResponse>}
 */
const methodDescriptor_AuthService_logOut = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/logOut',
  grpc.web.MethodType.UNARY,
  proto.Auth.OutRequest,
  proto.Auth.OutResponse,
  /**
   * @param {!proto.Auth.OutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.OutResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.OutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.OutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.OutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.logOut =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/logOut',
      request,
      metadata || {},
      methodDescriptor_AuthService_logOut,
      callback);
};


/**
 * @param {!proto.Auth.OutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.OutResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.logOut =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/logOut',
      request,
      metadata || {},
      methodDescriptor_AuthService_logOut);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.RegisterRequest,
 *   !proto.Auth.RegisterResponse>}
 */
const methodDescriptor_AuthService_userRegister = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/userRegister',
  grpc.web.MethodType.UNARY,
  proto.Auth.RegisterRequest,
  proto.Auth.RegisterResponse,
  /**
   * @param {!proto.Auth.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.userRegister =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/userRegister',
      request,
      metadata || {},
      methodDescriptor_AuthService_userRegister,
      callback);
};


/**
 * @param {!proto.Auth.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.userRegister =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/userRegister',
      request,
      metadata || {},
      methodDescriptor_AuthService_userRegister);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.CheckUserNameRequest,
 *   !proto.Auth.CheckUserNameResponse>}
 */
const methodDescriptor_AuthService_checkUserName = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/checkUserName',
  grpc.web.MethodType.UNARY,
  proto.Auth.CheckUserNameRequest,
  proto.Auth.CheckUserNameResponse,
  /**
   * @param {!proto.Auth.CheckUserNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.CheckUserNameResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.CheckUserNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.CheckUserNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.CheckUserNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.checkUserName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/checkUserName',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkUserName,
      callback);
};


/**
 * @param {!proto.Auth.CheckUserNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.CheckUserNameResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.checkUserName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/checkUserName',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkUserName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.CheckPhoneRequest,
 *   !proto.Auth.CheckPhoneResponse>}
 */
const methodDescriptor_AuthService_checkPhone = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/checkPhone',
  grpc.web.MethodType.UNARY,
  proto.Auth.CheckPhoneRequest,
  proto.Auth.CheckPhoneResponse,
  /**
   * @param {!proto.Auth.CheckPhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.CheckPhoneResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.CheckPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.CheckPhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.CheckPhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.checkPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/checkPhone',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkPhone,
      callback);
};


/**
 * @param {!proto.Auth.CheckPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.CheckPhoneResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.checkPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/checkPhone',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkPhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.ValicodeRequest,
 *   !proto.Auth.ValicodeResponse>}
 */
const methodDescriptor_AuthService_getValicode = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/getValicode',
  grpc.web.MethodType.UNARY,
  proto.Auth.ValicodeRequest,
  proto.Auth.ValicodeResponse,
  /**
   * @param {!proto.Auth.ValicodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.ValicodeResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.ValicodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.ValicodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.ValicodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.getValicode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/getValicode',
      request,
      metadata || {},
      methodDescriptor_AuthService_getValicode,
      callback);
};


/**
 * @param {!proto.Auth.ValicodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.ValicodeResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.getValicode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/getValicode',
      request,
      metadata || {},
      methodDescriptor_AuthService_getValicode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.UserStatusRequest,
 *   !proto.Auth.UserStatusResponse>}
 */
const methodDescriptor_AuthService_getUserStatus = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/getUserStatus',
  grpc.web.MethodType.UNARY,
  proto.Auth.UserStatusRequest,
  proto.Auth.UserStatusResponse,
  /**
   * @param {!proto.Auth.UserStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.UserStatusResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.UserStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.UserStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.UserStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.getUserStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/getUserStatus',
      request,
      metadata || {},
      methodDescriptor_AuthService_getUserStatus,
      callback);
};


/**
 * @param {!proto.Auth.UserStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.UserStatusResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.getUserStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/getUserStatus',
      request,
      metadata || {},
      methodDescriptor_AuthService_getUserStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.UserPowerRequest,
 *   !proto.Auth.UserPowerResponse>}
 */
const methodDescriptor_AuthService_checkUserPower = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/checkUserPower',
  grpc.web.MethodType.UNARY,
  proto.Auth.UserPowerRequest,
  proto.Auth.UserPowerResponse,
  /**
   * @param {!proto.Auth.UserPowerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.UserPowerResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.UserPowerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.UserPowerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.UserPowerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.checkUserPower =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/checkUserPower',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkUserPower,
      callback);
};


/**
 * @param {!proto.Auth.UserPowerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.UserPowerResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.checkUserPower =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/checkUserPower',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkUserPower);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.UserIpRequest,
 *   !proto.Auth.UserIpResponse>}
 */
const methodDescriptor_AuthService_getUserIp = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/getUserIp',
  grpc.web.MethodType.UNARY,
  proto.Auth.UserIpRequest,
  proto.Auth.UserIpResponse,
  /**
   * @param {!proto.Auth.UserIpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.UserIpResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.UserIpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.UserIpResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.UserIpResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.getUserIp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/getUserIp',
      request,
      metadata || {},
      methodDescriptor_AuthService_getUserIp,
      callback);
};


/**
 * @param {!proto.Auth.UserIpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.UserIpResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.getUserIp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/getUserIp',
      request,
      metadata || {},
      methodDescriptor_AuthService_getUserIp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.RecommendationsRequest,
 *   !proto.Auth.RecommendationsResponse>}
 */
const methodDescriptor_AuthService_recommendations = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/recommendations',
  grpc.web.MethodType.UNARY,
  proto.Auth.RecommendationsRequest,
  proto.Auth.RecommendationsResponse,
  /**
   * @param {!proto.Auth.RecommendationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.RecommendationsResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.RecommendationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.RecommendationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.RecommendationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.recommendations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/recommendations',
      request,
      metadata || {},
      methodDescriptor_AuthService_recommendations,
      callback);
};


/**
 * @param {!proto.Auth.RecommendationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.RecommendationsResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.recommendations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/recommendations',
      request,
      metadata || {},
      methodDescriptor_AuthService_recommendations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.TryOutRequest,
 *   !proto.Auth.TryOutResponse>}
 */
const methodDescriptor_AuthService_tryOut = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/tryOut',
  grpc.web.MethodType.UNARY,
  proto.Auth.TryOutRequest,
  proto.Auth.TryOutResponse,
  /**
   * @param {!proto.Auth.TryOutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.TryOutResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.TryOutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.TryOutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.TryOutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.tryOut =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/tryOut',
      request,
      metadata || {},
      methodDescriptor_AuthService_tryOut,
      callback);
};


/**
 * @param {!proto.Auth.TryOutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.TryOutResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.tryOut =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/tryOut',
      request,
      metadata || {},
      methodDescriptor_AuthService_tryOut);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.PhoneCodeRequest,
 *   !proto.Auth.PhoneCodeResponse>}
 */
const methodDescriptor_AuthService_sendPhoneCode = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/sendPhoneCode',
  grpc.web.MethodType.UNARY,
  proto.Auth.PhoneCodeRequest,
  proto.Auth.PhoneCodeResponse,
  /**
   * @param {!proto.Auth.PhoneCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.PhoneCodeResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.PhoneCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.PhoneCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.PhoneCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.sendPhoneCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/sendPhoneCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_sendPhoneCode,
      callback);
};


/**
 * @param {!proto.Auth.PhoneCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.PhoneCodeResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.sendPhoneCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/sendPhoneCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_sendPhoneCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.PhoneLoginRequest,
 *   !proto.Auth.LoginResponse>}
 */
const methodDescriptor_AuthService_phoneLogin = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/phoneLogin',
  grpc.web.MethodType.UNARY,
  proto.Auth.PhoneLoginRequest,
  proto.Auth.LoginResponse,
  /**
   * @param {!proto.Auth.PhoneLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.PhoneLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.phoneLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/phoneLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_phoneLogin,
      callback);
};


/**
 * @param {!proto.Auth.PhoneLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.phoneLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/phoneLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_phoneLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.CheckPhoneCodeRequest,
 *   !proto.Auth.CheckPhoneCodeResponse>}
 */
const methodDescriptor_AuthService_checkPhoneCode = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/checkPhoneCode',
  grpc.web.MethodType.UNARY,
  proto.Auth.CheckPhoneCodeRequest,
  proto.Auth.CheckPhoneCodeResponse,
  /**
   * @param {!proto.Auth.CheckPhoneCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.CheckPhoneCodeResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.CheckPhoneCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.CheckPhoneCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.CheckPhoneCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.checkPhoneCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/checkPhoneCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkPhoneCode,
      callback);
};


/**
 * @param {!proto.Auth.CheckPhoneCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.CheckPhoneCodeResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.checkPhoneCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/checkPhoneCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_checkPhoneCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.ResetPasswordRequest,
 *   !proto.Auth.ResetPasswordResponse>}
 */
const methodDescriptor_AuthService_resetPassword = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/resetPassword',
  grpc.web.MethodType.UNARY,
  proto.Auth.ResetPasswordRequest,
  proto.Auth.ResetPasswordResponse,
  /**
   * @param {!proto.Auth.ResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.ResetPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.ResetPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.ResetPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.resetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/resetPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_resetPassword,
      callback);
};


/**
 * @param {!proto.Auth.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.ResetPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.resetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/resetPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_resetPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.GetAppIdRequest,
 *   !proto.Auth.GetAppIdResponse>}
 */
const methodDescriptor_AuthService_getAppId = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/getAppId',
  grpc.web.MethodType.UNARY,
  proto.Auth.GetAppIdRequest,
  proto.Auth.GetAppIdResponse,
  /**
   * @param {!proto.Auth.GetAppIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.GetAppIdResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.GetAppIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.GetAppIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.GetAppIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.getAppId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/getAppId',
      request,
      metadata || {},
      methodDescriptor_AuthService_getAppId,
      callback);
};


/**
 * @param {!proto.Auth.GetAppIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.GetAppIdResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.getAppId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/getAppId',
      request,
      metadata || {},
      methodDescriptor_AuthService_getAppId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.ThirdLoginRequest,
 *   !proto.Auth.ThirdLoginResponse>}
 */
const methodDescriptor_AuthService_thirdLogin = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/thirdLogin',
  grpc.web.MethodType.UNARY,
  proto.Auth.ThirdLoginRequest,
  proto.Auth.ThirdLoginResponse,
  /**
   * @param {!proto.Auth.ThirdLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.ThirdLoginResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.ThirdLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.ThirdLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.ThirdLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.thirdLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/thirdLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_thirdLogin,
      callback);
};


/**
 * @param {!proto.Auth.ThirdLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.ThirdLoginResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.thirdLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/thirdLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_thirdLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Auth.PhoneBindRequest,
 *   !proto.Auth.phoneBindResponse>}
 */
const methodDescriptor_AuthService_phoneBind = new grpc.web.MethodDescriptor(
  '/Auth.AuthService/phoneBind',
  grpc.web.MethodType.UNARY,
  proto.Auth.PhoneBindRequest,
  proto.Auth.phoneBindResponse,
  /**
   * @param {!proto.Auth.PhoneBindRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Auth.phoneBindResponse.deserializeBinary
);


/**
 * @param {!proto.Auth.PhoneBindRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Auth.phoneBindResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Auth.phoneBindResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Auth.AuthServiceClient.prototype.phoneBind =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Auth.AuthService/phoneBind',
      request,
      metadata || {},
      methodDescriptor_AuthService_phoneBind,
      callback);
};


/**
 * @param {!proto.Auth.PhoneBindRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Auth.phoneBindResponse>}
 *     Promise that resolves to the response
 */
proto.Auth.AuthServicePromiseClient.prototype.phoneBind =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Auth.AuthService/phoneBind',
      request,
      metadata || {},
      methodDescriptor_AuthService_phoneBind);
};


module.exports = proto.Auth;

