<template>
  <div style="width: 100%">
    <div v-show="!hasH5" class="home-content">
      <div class="home-back">
        <div class="home-font">
          <span class="home-font1">汇集全球海量人才资源，打造权威翻译团队 </span>
          <div class="home-font2">
            联合国语言服务供应商<span style="padding: 0 20px">中国翻译协会理事单位</span><span style="padding-right: 20px">汇集全球海量人才资源</span>38年专业翻译经验
          </div>
        </div>
      </div>
      <div class="home-main">
        <div class="home-zy">
          <div class="home-zy-header">
            <div class="home-zy-font1">专业翻译</div>
            <div class="home-zy-font2">
              最低 <span
                style="padding: 0 10px;color: #FF9F08;font-size: 22px;font-weight:Bold;font-family: MicrosoftYaHei-Bold">0.12</span>
              元/字
            </div>
          </div>
          <div class="home-fenlei" style="margin-top: 30px;">
            <img src="../assets/v2/home/img/home_duigou.png"><span style="padding-right: 73px;padding-left: 11px;">20+语种翻译</span>
            <img src="../assets/v2/home/img/home_duigou.png"><span style="padding-left: 11px;">专业审校</span>
          </div>
          <div class="home-fenlei" style="margin-top: 15px">
            <img src="../assets/v2/home/img/home_duigou.png"><span style="padding-left: 11px;padding-right: 71px;">多年翻译经验</span>
            <img src="../assets/v2/home/img/home_duigou.png"><span style="padding-left: 11px;">精心编排</span>
          </div>
          <div class="lines"></div>
          <div class="home-fenlei2" style="margin-top: 15px;">
            <span>专业论文</span> <span class="one">简历简介</span> <span class="two">证件资料</span> <span>合同标书</span>
          </div>
          <div class="home-fenlei2">
            <span>留学移民</span> <span class="one">公司介绍</span> <span class="two">文献出版</span> <span>创意翻译</span>
          </div>
          <div class="home-zy-footer">
            <div class="home-button" @click="this.$router.push('/trans-doc')">
              <span>立即上传</span>
            </div>
          </div>
        </div>
        <div class="home-null"></div>
        <div class="home-my">
          <div class="home-my-header">
            <div class="home-my-font1">英文母语润色</div>
            <div class="home-my-font2">
              专业母语润色 | 审校+质检服务
            </div>
          </div>
          <div class="home-fenlei" style="margin-top: 30px;">
            <img src="../assets/v2/home/img/home_duigou.png"><span style="padding-right: 73px;padding-left: 11px;">英语母语编辑</span>
            <img src="../assets/v2/home/img/home_duigou.png"><span style="padding-left: 11px;">语法检查</span>
          </div>
          <div class="home-fenlei" style="margin-top: 15px">
            <img src="../assets/v2/home/img/home_duigou.png"><span
              style="padding-left: 11px;padding-right: 103px;">学术检查</span>
            <img src="../assets/v2/home/img/home_duigou.png"><span style="padding-left: 11px;">逻辑检查</span>
          </div>
          <div class="lines"></div>
          <div class="home-fenlei2" style="margin-top: 15px;">
            <span>SCI论文</span> <span class="one">学位论文</span> <span>大会发言</span>
          </div>
          <div class="home-fenlei2">
            <span>出版刊物</span> <span class="one">企业宣传</span> <span>工作汇报</span>
          </div>
          <div class="home-my-footer">
            <div class="home-button" @click="this.$router.push('/trans-polish')">
              <span>立即下单</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="hasH5" class="trans-pro-main">
      <div class="trans-pro-main-head">
        <div class="trans-pro-main-head-font">
          汇集全球海量人才资源
          <br/>
          打造权威翻译团队
        </div>
        <div class="trans-pro-main-head-info">
          <div class="trans-pro-main-head-info-font">
            <div>联合国语言服务供应商</div>
            <div>中国翻译协会理事单位</div>
          </div>
          <div class="trans-pro-main-head-info-font">
            <div>汇集全球海量人才资源</div>
            <div>38年专业翻译经验</div>
          </div>
        </div>
      </div>
      <div class="trans-pro-main-lj">
        <div class="trans-pro-main-lj-wen"/>
        <div class="trans-pro-main-lj-zyfy">
          专业翻译
        </div>
        <div class="trans-pro-main-lj-money">
          最低 <span style="color: #E25900">0.12</span> 元/字
        </div>
        <div class="trans-pro-main-lj-tip">
          <div class="trans-pro-main-lj-tip-font">
            <div><img src="../assets/trans-pro-lj.png" alt=""/>20+语种翻译</div>
            <div><img src="../assets/trans-pro-lj.png" alt=""/>专业审校</div>
          </div>
          <div class="trans-pro-main-lj-tip-font">
            <div><img src="../assets/trans-pro-lj.png" alt=""/>多年翻译经验</div>
            <div><img src="../assets/trans-pro-lj.png" alt=""/>精心编排</div>
          </div>
        </div>
        <div class="trans-pro-main-hr"></div>
        <div class="trans-pro-main-lj-desc">
          <div class="trans-pro-main-lj-desc-tip-font">
            <div>专业论文</div>
            <div>简历简介</div>
            <div>证件资料</div>
          </div>
          <div class="trans-pro-main-lj-desc-tip-font">
            <div>合同标书</div>
            <div>留学移民</div>
            <div>公司介绍</div>
          </div>
          <div class="trans-pro-main-lj-desc-tip-font">
            <div>文献出版</div>
            <div>创意翻译</div>
            <div></div>
          </div>
        </div>
        <div class="trans-pro-main-lj-button" @click="toPath('/trans-doc')">
          <span>立即上传</span>
        </div>
      </div>
      <div class="trans-pro-main-lj" style="margin-top: 36px;margin-bottom: 30px">
        <div class="trans-pro-main-lj-wen"/>
        <div class="trans-pro-main-lj-zyfy">
          英文母语润色
        </div>
        <div class="trans-pro-main-lj-money">
          专业母语润色 | 审校+质检服务
        </div>
        <div class="trans-pro-main-lj-tip">
          <div class="trans-pro-main-lj-tip-font">
            <div><img src="../assets/trans-pro-lj.png" alt=""/>英语母语编辑</div>
            <div><img src="../assets/trans-pro-lj.png" alt=""/>语法检查</div>
          </div>
          <div class="trans-pro-main-lj-tip-font">
            <div><img src="../assets/trans-pro-lj.png" alt=""/>学术检查</div>
            <div><img src="../assets/trans-pro-lj.png" alt=""/>逻辑检查</div>
          </div>
        </div>
        <div class="trans-pro-main-hr"></div>
        <div class="trans-pro-main-lj-desc">
          <div class="trans-pro-main-lj-desc-tip-font">
            <div>SCI论文</div>
            <div>学位论文</div>
            <div>大会发言</div>
          </div>
          <div class="trans-pro-main-lj-desc-tip-font">
            <div>出版刊物</div>
            <div>企业宣传</div>
            <div>工作汇报</div>
          </div>
        </div>
        <div class="trans-pro-main-lj-button" @click="toPath('/trans-polish')">
          <span>立即下单</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      hasH5: false,
    }
  },
  mounted() {
    this.hasH5 = this.isMobile()
  },
  methods: {
    toPath(uri) {
      this.$router.push(uri)
    },
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
  }
}
</script>

<style lang="less" scoped>
.home-content {
  background: url(../assets/v2/home/img/home_back1.png) #FFFFFF no-repeat;
  height: 860px;
  width: 100%;
  background-position-x: center;

  .home-back {
    width: 100%;
    height: 378px;

    .home-font {
      height: 196px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;

      .home-font1 {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        z-index: 1;
      }

      .home-font2 {
        width: 650px;
        background: url(../assets/v2/home/img/home_back_sub1.png) no-repeat;
        background-size: 100% 100%;
        height: 49px;
        border-radius: 5px;
        font-size: 14px;
        margin-top: -15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #747373;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        line-height: 35px;
      }
    }
  }

  .home-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -155px;

    .home-zy {
      width: 450px;
      height: 452px;
      box-shadow: 0px 0px 38px 0px rgba(140, 140, 140, 0.15);
      border-radius: 8px;
      background: url(../assets/v2/home/img/home_back_right.png) #FFFFFF no-repeat;
      background-position-x: right;
      background-position-y: top;
      display: flex;
      flex-flow: column;
      align-items: center;

      .home-zy-header {
        box-shadow: 0px 0px 38px 0px rgba(140, 140, 140, 0.15);
        border-radius: 8px 8px 0px 0px;
        padding-top: 33px;
        height: 98px;
        width: 100%;
      }

      .home-zy-back1 {
        background: url(../assets/home-zy.png) #ffffff00 no-repeat 50%;
        height: 140px;
      }

      .home-zy-font1 {
        background: url(../assets/v2/home/img/homg_zt_back_1.png) no-repeat;
        background-position-y: center;
        background-position-x: center;
        text-align: center;
        overflow-wrap: break-word;
        color: #3E6D77;
        font-size: 26px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: 700;
        white-space: nowrap;
      }

      .home-zy-font2 {
        height: 30px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #008080;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
      }


      .home-fenlei {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        img {
          height: 17px;
          width: 17px;
        }

        .one {
          padding: 0 55px;
        }

        .two {
          padding-right: 55px;
        }
      }

      .home-fenlei2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;

        .one {
          padding: 0 30px;
        }

        .two {
          padding-right: 35px;
        }
      }

      .lines {
        width: 333px;
        height: 1px;
        background: #ACACAC;
        opacity: 0.2;
        margin-top: 35px;
      }

      .home-zy-footer {
        margin-top: 5px;
        width: 448px;
        height: 101px;
        background: url(../assets/v2/home/img/home-footer-back.png) no-repeat;
        background-position-y: top;
        background-position-x: center;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .home-button {
          background: #3E6D77;
          cursor: pointer;
          width: 224px;
          height: 48px;
          border-radius: 10px;

          span {
            width: 64px;
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 46px;
          }
        }

        .home-button:hover {
          background: rgba(255, 255, 255, 0);
          border: 1px solid #01A8A0;
          border-radius: 10px;
          cursor: pointer;
          width: 224px;
          height: 48px;

          span {
            width: 64px;
            height: 16px;
            font-size: 16px;
            line-height: 46px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #01A8A0;
          }
        }
      }
    }

    .home-null {
      width: 173px;
    }

    .home-my {
      width: 450px;
      height: 452px;
      box-shadow: 0px 0px 38px 0px rgba(140, 140, 140, 0.15);
      border-radius: 8px;
      background: url(../assets/v2/home/img/home_back_right.png) #FFFFFF no-repeat;
      background-position-x: right;
      background-position-y: top;
      display: flex;
      flex-flow: column;
      align-items: center;

      .home-my-header {
        box-shadow: 0px 0px 38px 0px rgba(140, 140, 140, 0.15);
        border-radius: 8px 8px 0px 0px;
        padding-top: 33px;
        height: 98px;
        width: 100%;
      }

      .home-my-back1 {
        background: url(../assets/home-my.png) #ffffff00 no-repeat 50%;
        height: 140px;
      }

      .home-my-font1 {
        background: url(../assets/v2/home/img/home_my_back_1.png) no-repeat;
        background-position-y: center;
        background-position-x: center;
        text-align: center;
        overflow-wrap: break-word;
        color: #3E6D77;
        font-size: 26px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: 700;
        white-space: nowrap;
      }

      .home-my-font2 {
        height: 30px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #008080;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
      }

      .lines {
        width: 333px;
        height: 1px;
        background: #ACACAC;
        opacity: 0.2;
        margin-top: 35px;
      }

      .home-fenlei2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;

        .one {
          padding: 0 80px;
        }
      }

      .home-fenlei {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        img {
          height: 17px;
          width: 17px;
        }

        .one {
          padding: 0 110px;
        }

      }

      .home-my-footer {
        margin-top: 5px;
        width: 448px;
        height: 101px;
        background: url(../assets/v2/home/img/home-footer-back.png) no-repeat;
        background-position-y: top;
        background-position-x: center;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .home-button {
          background: #3E6D77;
          cursor: pointer;
          width: 224px;
          height: 48px;
          border-radius: 10px;

          span {
            width: 64px;
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 46px;
          }
        }

        .home-button:hover {
          background: rgba(255, 255, 255, 0);
          border: 1px solid #01A8A0;
          border-radius: 10px;
          cursor: pointer;
          width: 224px;
          height: 48px;

          span {
            width: 64px;
            height: 16px;
            font-size: 16px;
            line-height: 46px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #01A8A0;
          }
        }
      }
    }
  }
}

.trans-pro-main {
  min-width: 765px;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;

  .trans-pro-main-head {
    width: 100%;
    height: 435px;
    display: flex;
    align-items: center;
    flex-flow: column;
    background: url("../assets/trans-pro-bac.png") #F5F5F5 no-repeat;
    background-size: 768px;
    background-position: center;

    .trans-pro-main-head-font {
      margin-top: 54px;
      margin-bottom: 35px;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
      width: 457px;
    }

    .trans-pro-main-head-info {
      width: 457px;
      height: 75px;
      background: #DDDDDD;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;

      .trans-pro-main-head-info-font {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
        display: flex;
        width: 457px;

        div {
          flex: 1;
        }

        div:nth-child(1) {
          margin-left: 20px;
        }
      }
    }
  }

  .trans-pro-main-lj {
    width: 709px;
    height: 685px;
    background: url("../assets/pro-zy.png") no-repeat;
    margin-top: -109px;
    display: flex;
    align-items: center;
    flex-flow: column;

    .trans-pro-main-lj-wen {
      margin-top: 34px;
      width: 122px;
      height: 122px;
      background: url("../assets/pro-zy-wen.png") no-repeat;
    }

    .trans-pro-main-lj-zyfy {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2F2F2F;
      margin-top: 25px;
    }

    .trans-pro-main-lj-money {
      margin-top: 14px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #8A8989;
    }

    .trans-pro-main-lj-tip {
      width: 359px;
      height: 71px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      margin-top: 44px;
      line-height: 48px;

      .trans-pro-main-lj-tip-font {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
        display: flex;
        width: 370px;
        margin-bottom: 23px;

        div {
          flex: 1;
          display: flex;
          align-items: center;

          img {
            margin-right: 18px;
          }
        }

      }
    }

    .trans-pro-main-hr {
      margin-top: 25px;
      width: 581px;
      height: 1px;
      border: 1px solid #999999;
      opacity: 0.6;
      background: #999999;
    }

    .trans-pro-main-lj-desc {
      margin-top: 28px;
      width: 481px;
      height: 110px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 48px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .trans-pro-main-lj-desc-tip-font {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
        display: flex;
        width: 530px;
        margin-bottom: 10px;

        div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin-right: 18px;
          }
        }

      }
    }

    .trans-pro-main-lj-button {
      margin-top: 30px;
      width: 261px;
      height: 60px;
      background: #063F95;
      border-radius: 35px 0px 35px 0px;
      text-align: center;

      span {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 56px;
      }
    }
  }
}
</style>