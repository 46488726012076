<template>
  <div :style="!hasH5?'min-width: 1200px;width: 100%':'min-width: 768px;'">
    <div class="header-main" v-if="this.$route.path!=='/transProPrice'&&this.$route.path!=='/transProPrice'&&this.$route.path!=='/trans-pro-proto'">
      <Header :choose-data="chooseData"/>
    </div>
<!--    <div class="header-nav" v-show="!hasH5" v-if="this.$route.path!=='/trans-pro-proto'">-->
<!--      <Navigation/>-->
<!--    </div>-->
    <div class="content">
      <router-view @changeActiveStep="changeStep"></router-view>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      hasH5: false,
      chooseData: 0
    };
  },
  mounted() {
    this.hasH5 = this.isMobile()
  },
  methods: {
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    changeStep(val) {
      this.chooseData = val
    }
  }
}
</script>

<style>

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.header-nav {
  left: 0px;
  top: 75px;
  background-color: rgba(227, 225, 225, 1);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.header-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
