import {AuthServicePromiseClient} from '../../../api/authService_grpc_web_pb'
import {TranslateServicePromiseClient} from '../../../api/translateService_grpc_web_pb'
import { useCookies } from "vue3-cookies";
// 从 env 配置文件读取接口域名
 const host = 'https://wesitrans.com';
//const host = 'http://192.168.31.76:6799';
const allInterceptor = function () {
}

allInterceptor.prototype.intercept = function (request, invoker) {
    
    const { cookies } = useCookies();
    return invoker(request).then((response) => {
        const metadata = response.getMetadata();
        if (metadata['code']) {
            localStorage.setItem('code', metadata['code']);
        }
        
        if (metadata['auth-context'] || metadata['token']) {
            localStorage.setItem('auth-context', metadata['auth-context']);
            localStorage.setItem('token', metadata['token']);
            cookies.set("token", metadata['token'],30*60*1000,'/','.wesitrans.com',true,'none')
        }
        return response;
    });
}

const interceptor = new allInterceptor()
export const translateServiceClient = new TranslateServicePromiseClient(host, null, {
    'unaryInterceptors': [interceptor]
});
export const authServiceClient = new AuthServicePromiseClient(host, null, {
    'unaryInterceptors': [interceptor]
});
