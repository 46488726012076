<template>
  <div class="nav">
    <div class="nav-border">
      <div>
        <label>
          <div @click="handleSelect('/')">首页</div>
        </label>
        <label>
          <div @click="handleSelect('/translate')">立即翻译</div>
        </label>
        <label>
          <div @click="handleSelect('/translate-pro')" class="index">人工翻译</div>
        </label>
        <label>
          <div @click="handleSelect('/convert')">PDF转换</div>
        </label>
        <label>
          <div @click="handleSelect('/list-pro')">历史文档</div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      activeIndex: '1',
    }
  },
  methods: {
    handleSelect(url) {
      debugger
      if ('/translate-pro' === url) {
        this.$router.push('/');
        return
      }
      document.location.href = 'https://wesitrans.com' + url;
    },
  }
}
</script>

<style lang="less" scoped>
.nav {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 58px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  background: #ffffff;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;

  .nav-border {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 56px;
    background: #e6e6e6;

    div {
      display: flex;
      width: 1200px;
      justify-content: flex-start;
      align-items: center;

      label {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        width: 10%;
        cursor: pointer;

        .index {
          display: flex;
          width: 64px;
          height: 58px;
          border-bottom: 2px solid #0049B9;
          color: #0049B9;
          justify-content: center;
          align-items: center;
          padding: 0;
        }

        div:nth-child(1):hover {
          width: 38px;
          height: 58px;
          border-bottom: 2px solid #0049B9;
          color: #0049B9;
          justify-content: center;
          align-items: center;
        }
      }

      label:nth-child(1) {
        width: 7%;
      }

      label:nth-child(2) {
        div:nth-child(1):hover {
          width: 64px;
          height: 58px;
          border-bottom: 2px solid #0049B9;
          color: #0049B9;
        }
      }

      label:nth-child(3) {
        div:nth-child(1):hover {
          width: 64px;
          height: 58px;
          border-bottom: 2px solid #0049B9;
          color: #0049B9;
        }
      }

      label:nth-child(4) {
        div:nth-child(1):hover {
          width: 64px;
          height: 58px;
          border-bottom: 2px solid #0049B9;
          color: #0049B9;
        }
      }

      label:nth-child(5) {
        div:nth-child(1):hover {
          width: 64px;
          height: 58px;
          border-bottom: 2px solid #0049B9;
          color: #0049B9;
        }
      }
    }
  }

}
</style>