import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElement from './plugins/element'
import axios from "axios";
import layer from "layui-layer";
import VueCookies from 'vue3-cookies'
import jwt_decode from "jwt-decode";
import './assets/css/global.css'
import {ArrowRight, Right} from '@element-plus/icons'

// 超时时间
axios.defaults.timeout = 20 * 1000
// 凭证
axios.defaults.withCredentials = true

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    console.log(config)
    // 在发送请求之前做些什么
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
})
let elementApp = createApp(App);
elementApp.config.globalProperties.$http = axios
elementApp.config.globalProperties.$layer = layer
elementApp.config.globalProperties.$jwt = jwt_decode
installElement(elementApp)
elementApp.component('d-arrow-right', ArrowRight).component('right', Right)
elementApp.use(store).use(router).use(VueCookies).mount('#app')
