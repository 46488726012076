<template>
  <div style="width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    height: 70px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.18);
    ">
    <div v-if="!hasH5" class="header-v2">
      <div class="header-left" style="cursor: pointer">
        <img @click="toPath('/index')" src="../assets/v2/header/img/logo.png" alt="" width="104" height="28">
        <span class="text_1">
            <span @click="toPath('/')">首页</span>
            <span>
              <el-dropdown @command="logOut">
                <span>
                  产品
                </span>
                 <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="txtTrans" style="z-index: 1;font-weight: 800">
                    <span>
                      文本翻译
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item command="docTrans" style="z-index: 1;font-weight: 800">
                    <span>
                      文档翻译
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item command="mediaTrans" style="z-index: 1;font-weight: 800">
                    <span>
                      视频翻译
                      <span style="margin-left: 10px">
                        (服务升级中，敬请期待)
                        <span style="position: absolute;margin-top: -10px;margin-left: -20px;">
                          <img src="../assets/new_icon.png" alt="">
                        </span>
                      </span>
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item command="imgTrans" style="z-index: 1;font-weight: 800">
                    <span>
                      图片翻译
                      <span style="margin-left: 10px">
                        (服务升级中，敬请期待)
                        <span style="position: absolute;margin-top: -10px;margin-left: -20px;">
                          <img src="../assets/new_icon.png" alt="">
                        </span>
                      </span>
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item command="xlsTrans" style="z-index: 1;font-weight: 800">
                    <span>
                      表格翻译
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item command="musicTrans" style="z-index: 1;font-weight: 800">
                    <span>
                      音频翻译
                      <span style="margin-left: 10px">
                        (服务升级中，敬请期待)
                        <span style="position: absolute;margin-top: -10px;margin-left: -20px;">
                          <img src="../assets/new_icon.png" alt="">
                        </span>
                      </span>
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
                 </template>
              </el-dropdown>
              <img
                  class="thumbnail_4"
                  referrerpolicy="no-referrer"
                  src="../assets/v2/header/img/operate.png"
              />
            </span>
            <span>
              <el-dropdown @command="logOut">
                  <span>
                    服务
                  </span>
                <template #dropdown>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="toTransPro" style="z-index: 1;font-weight: 800">
                        <span>
                          人工翻译
                        </span>
                    </el-dropdown-item>
                    <el-dropdown-item command="standLangSet" style="z-index: 1;font-weight: 800">
                        <span>
                          术语定制
                          <span style="margin-left: 10px">
                            (服务升级中，敬请期待)
                            <span style="position: absolute;margin-top: -10px;margin-left: -20px;">
                              <img src="../assets/new_icon.png" alt="">
                            </span>
                          </span>
                        </span>
                    </el-dropdown-item>
                    <el-dropdown-item command="convertDoc" style="z-index: 1;font-weight: 800">
                        <span>
                          文档转换
                        </span>
                    </el-dropdown-item>
                    <el-dropdown-item command="mySelfDoc" style="z-index: 1;font-weight: 800">
                        <span>
                          我的文档
                        </span>
                    </el-dropdown-item>
                   </el-dropdown-menu>
                </template>
              </el-dropdown>
              <img
                  class="thumbnail_4"
                  referrerpolicy="no-referrer"
                  src="../assets/v2/header/img/operate.png"
              />
            </span>
        </span>
      </div>
      <div class="header-right" style="cursor: pointer">
        <div class="flex-row" style="align-items: center;">
          <img class="thumbnail_1"
               @click="toPath('/help')"
               referrerpolicy="no-referrer"
               src="../assets/v2/header/img/help.png"/>
          <span class="text_2" @click="toPath('/help')">帮助</span>
          <span class="text_2" v-if="groupUserInfo==='机构登录'"
                @click="showIframe('/login')">
                {{ groupUserInfo }}
              </span>
          <el-dropdown @command="logOut" v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'"
                       style="display: flex;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">
                <span v-show="groupUserInfo!==''&&groupUserInfo!=='机构登录'" class="text_2">{{
                    groupUserInfo
                  }}</span>
            <template #dropdown>
            <el-dropdown-menu v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'" slot="dropdown">
              <el-dropdown-item v-for="(item,index) in groupUserInfos" :key="index" :command="item.name"
                                style="z-index: 1;font-weight: 800">
                    <span style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">{{
                        item.name
                      }}</span>
                <span
                    style="margin-left:10px; font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;"
                    v-if="!item.isIpScore">退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
          <img class="thumbnail_1"
               @click="userInfo!==''?toCenter:''"
               referrerpolicy="no-referrer"
               src="../assets/v2/header/img/login.png"/>
          <el-dropdown @command="logOut" v-if="showloginOut"
                       style="display: flex;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">
                  <span v-show="userInfo!==''" class="text_2">
                    {{ userInfo }}
                  </span>
            <template #dropdown>
            <el-dropdown-menu v-if="showloginOut" slot="dropdown">
              <el-dropdown-item command="toCenter" style="z-index: 1;font-weight: 800">
                  <span
                      style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">个人中心</span>
              </el-dropdown-item>
              <el-dropdown-item style="z-index: 1;font-weight: 800">
                    <span
                        style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span class="text_2" v-show="userInfo===''">
                  <span @click="showIframe('/login')">登录</span>
                    /
                  <span @click="showIframe('/register')">注册</span>
                </span>
          <span v-if="isShowInRoaming&&showRoaming">
                  <span class="text_2">｜</span>
                  <el-popover
                      @hide="removeQrcode"
                      @show="getQrcode"
                      placement="top-start"
                      title="扫一扫开通漫游"
                      width="150"
                      trigger="hover">
                    <el-image
                        :src="qrcodeUrl"
                        fit="contain"
                        style="width: 150px; height: 150px"
                        alt="开通漫游">
                    </el-image>
                    <el-button type="text" slot="reference" class="text_2"
                               style="margin-top: 4px;cursor: pointer">开通漫游</el-button>
                  </el-popover>
                  <span style="position: absolute;margin-top: -8px;margin-left: -20px;">
                    <img src="../assets/new_icon.png" alt="">
                  </span>
                </span>
        </div>
      </div>
    </div>
    <div v-if="false" class="header">
      <div class="header-left" style="cursor: pointer">
        <img @click="toPath('/index')" src="../assets/logo-big.png" alt="" width="135" height="49">
      </div>
      <div class="header-right" style="cursor: pointer">
        <div style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;margin-right: 10px">
          <span v-if="groupUserInfo==='机构登录'" @click="showIframe('/loginTwo')">{{ groupUserInfo }}</span>
          <el-dropdown @command="logOut" v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'"
                       style="display: flex;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;background: #f5f5f5">
            <span v-show="groupUserInfo!==''&&groupUserInfo!=='机构登录'" class="title_hide">{{ groupUserInfo }}</span>
            <template #dropdown>
              <el-dropdown-menu v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'" slot="dropdown">
                <el-dropdown-item v-for="(item,index) in groupUserInfos" :key="index" :command="item.name"
                                  style="z-index: 1;font-weight: 800">
                <span style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">{{
                    item.name
                  }}</span>
                  <span
                      style="margin-left:10px; font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;"
                      v-if="!item.isIpScore">退出</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div
            style="align-items: center;display: flex;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">
          <el-dropdown @command="logOut" v-if="showloginOut"
                       style="display: flex;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;background: #f5f5f5">
            <span v-show="userInfo!==''" class="title_hide">
              {{ userInfo }}
            </span>
            <template #dropdown>
              <el-dropdown-menu v-if="showloginOut" slot="dropdown">
                <el-dropdown-item command="toCenter" style="z-index: 1;font-weight: 800">
                  <span
                      style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">个人中心</span>
                </el-dropdown-item>
                <el-dropdown-item style="z-index: 1;font-weight: 800" :command="userId">
                  <span style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">退出</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span @click="toCenter">
            <img v-if="userType==='0'" class="user-img" src="../assets/person_no.png" alt="">
            <img v-if="userType==='1'" class="user-img" src="../assets/child_no.png" alt="">
            <img v-if="userType==='2'" class="user-img" src="../assets/roaming_no.png" alt="">
          </span>
        </div>
        <div v-show="userInfo===''" @click="showIframe('/loginTwo')">登录</div>
        <div v-show="userInfo===''" @click="showIframe('/registerTwo')">注册</div>
        <div>｜</div>
        <div v-if="isShowInRoaming&&showRoaming">
          <el-popover
              @hide="removeQrcode"
              @show="getQrcode"
              placement="top-start"
              title="扫一扫开通漫游"
              width="150"
              trigger="hover">
            <el-image
                :src="qrcodeUrl"
                fit="contain"
                style="width: 150px; height: 150px"
                alt="开通漫游">
            </el-image>
            <el-button type="text" slot="reference">开通漫游</el-button>
          </el-popover>
          <div style="margin-top: -28px;margin-left: -25px;"><img src="../assets/new_icon.png" alt=""></div>
        </div>
        <div @click="toPath('/help')">
          帮助
        </div>
      </div>
    </div>
    <div v-if="hasH5" class="main-index">
      <div class="header">
        <div class="login" v-show="userInfo===''" @click="toPath('/h5/login')">
          登录
        </div>
        <div class="login" v-show="userInfo!==''">
          <el-dropdown @command="logOut" v-if="showloginOut"
                       style="display: flex;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;background: #f5f5f5">
            <span v-show="userInfo!==''" class="title_hide"
                  style="overflow-x: hidden;text-overflow: ellipsis;width: 100px;white-space: nowrap;">
              {{ userInfo }}
            </span>
            <template #dropdown>
              <el-dropdown-menu v-if="showloginOut" slot="dropdown">
                <el-dropdown-item command="toCenter" style="z-index: 1;font-weight: 800">
                  <span
                      style="font-size: 22px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">充值中心</span>
                </el-dropdown-item>
                <el-dropdown-item command="toAccount" style="z-index: 1;font-weight: 800">
                  <span
                      style="font-size: 22px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">个人设置</span>
                </el-dropdown-item>
                <el-dropdown-item style="z-index: 1;font-weight: 800">
                  <span style="font-size: 22px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">退出</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span @click="toCenter">
            <img v-if="userType==='0'" class="user-img" src="../assets/person_no.png" alt="">
            <img v-if="userType==='1'" class="user-img" src="../assets/child_no.png" alt="">
            <img v-if="userType==='2'" class="user-img" src="../assets/roaming_no.png" alt="">
          </span>
        </div>
        <div v-if="groupUserInfo==='机构登录'" class="login" @click="toPath('/h5/login')">
          <span>机构登录</span>
        </div>
        <div v-if="groupUserInfo!=='机构登录'"
             style="overflow-x: hidden;text-overflow: ellipsis;width: 130px;white-space: nowrap;" class="login">
          <el-dropdown @command="logOut" v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'"
                       style="display: flex;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;background: #f5f5f5">
          <span v-show="groupUserInfo!==''&&groupUserInfo!=='机构登录'"
                style="overflow-x: hidden;text-overflow: ellipsis;width: 130px;white-space: nowrap;" class="title_hide">{{
              groupUserInfo
            }}</span>
            <template #dropdown>
              <el-dropdown-menu v-if="groupUserInfo!==''&&groupUserInfo!=='机构登录'" slot="dropdown">
                <el-dropdown-item v-for="(item,index) in groupUserInfos" :key="index" :command="item.name"
                                  style="z-index: 1;font-weight: 800">
                <span style="font-size: 20px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">{{
                    item.name
                  }}</span>
                  <span
                      style="margin-left:10px; font-size: 20px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;"
                      v-if="!item.isIpScore">退出</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="select" style="font-size: 24px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;">
          人工翻译
        </div>
        <div class="select">
          <div @click="showSelect=!showSelect" class="select-option">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-show="showSelect" class="selected">
          <div @click="toPath('/index')">首页</div>
          <div @click="toPath('/translate')">立即翻译</div>
          <div @click="toPath('/trans-pro')">人工翻译</div>
          <div @click="toPath('/convert')">PDF转换</div>
          <div @click="toPath('/list')">历史文档</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authServiceClient, outRequest, userStatusRequest} from "@/tools/api/auth-api";

export default {
  name: "Header",
  props: {
    chooseData: {
      type: Number
    }
  },
  data() {
    return {
      showSelect: false,
      hasH5: false,
      qrcodeUrl: '',
      groupUserInfos: [],
      userInfo: '',
      userName: '',
      iframeState: false,
      showloginOut: false,
      showHeader: true,
      showRoaming: false,
      groupUserInfo: '',
      userId: '',
      userType: '',
      isRoamingTimer: undefined,
      isRoamingTimerOut: undefined,
      isShowInRoaming: false,
      checkLogin: undefined,
      oldToken: undefined
    }
  },
  watch: {
    chooseData: {
      immediate: true,
      handler: function () {
        if (this.chooseData !== 0) {
          if (this.hasH5) {
            document.location.href = "https://wesitrans.com/h5/login"
            return
          }
          this.showIframe('/loginTwo');
        }
      }
    }
  },
  created() {
    this.hasH5 = this.isMobile()
    const TOKEN_KEY = 'token';
    let token = this.$cookies.get("token")
    if (!token) {
      token = localStorage.getItem(TOKEN_KEY);
    }
    authServiceClient.getUserStatus(userStatusRequest, {
      [TOKEN_KEY]: token
    }).then(res => {
      if (!res.getStatus()) {
        localStorage.removeItem("token")
        localStorage.removeItem("auth-context")
        this.userInfo = "";
        this.groupUserInfo = "";
        this.showRoaming = false;
        return;
      }
      const user = localStorage.getItem('auth-context');
      if (!user) {
        return
      }
      const userJson = JSON.parse(user);
      const Base64 = require('js-base64').Base64;
      userJson.forEach(item => {
        if (item.type === 'Person' || item.type === 'Child') {
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          if (item.type === 'Person') {
            this.userType = '0'
          }
          if (item.type === 'Child') {
            this.userType = '1'
          }
          this.userInfo = realName
          this.userId = item.name;
          if (!item.isRoaming) {
            let roaming = localStorage.getItem(this.userId)
            if (!roaming || roaming !== '1') {
              this.showRoaming = true
            } else {
              this.showRoaming = false
              this.userType = '2'
            }
          } else {
            this.userType = '2'
          }
          if (this.groupUserInfo === '') {
            this.groupUserInfo = '机构登录';
          }
          this.showloginOut = true
        }
        if (item.type === 'Group') {
          this.groupUserInfos.push(item)
          let realName = '';
          if (item.realName !== '' && item.realName !== undefined) {
            realName = Base64.decode(item.realName);
          } else {
            realName = item.name;
          }
          this.groupUserInfo = realName;
          this.url = process.env.VUE_APP_WEB_LOGIN_URL;
        }
      })
    }).catch(err => {
      localStorage.removeItem("token")
      localStorage.removeItem("auth-context")
      this.userInfo = "";
      this.showRoaming = false
    });
  },
  methods: {
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    toCenter() {
      window.open("https://user.wesitrans.com/account", '_blank')
    },
    removeQrcode() {
      this.qrcodeUrl = "";
      clearInterval(this.isRoamingTimer);
      clearTimeout(this.isRoamingTimerOut);
    },
    getQrcode() {
      this.qrcodeUrl = "https://common.wesitrans.com/common/roaming/qrcode"
      this.isRoamingTimer = setInterval(this.roamingEvent, 1000)
      this.isRoamingTimerOut = setTimeout(this.clearTime, 60000);
    },
    toPath(url) {
      if (url === '/trans-pro') {
        this.$router.push('/')
        return
      }
      document.location.href = "https://wesitrans.com" + url
    },
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        this.$layer.open({
          skin: 'login-demo-class',
          area: ['885px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      } else {
        this.$layer.open({
          area: ['885px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    },
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = this.$jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        window.location.reload()
      }
    },
    clearTime() {
      clearInterval(this.isRoamingTimer);
      clearTimeout(this.isRoamingTimerOut);
    },
    roamingEvent() {
      if (this.userId && localStorage.getItem(this.userId) !== '1') {
        let request = checkRoamingRequest
        request.setUserId(this.userId)
        const TOKEN_KEY = "token";
        translateServiceClient.checkRoaming(request, {
          [TOKEN_KEY]: localStorage.getItem(TOKEN_KEY),
        }).then(res => {
          localStorage.setItem(this.userId, res.getMessage())
          if (res.getMessage() === '1') {
            window.location.reload()
          }
        }).catch(err => {
        })
      }
    },
    logOut(val) {
      if (val === "toCenter") {
        window.open("https://user.wesitrans.com?token=" + localStorage.getItem("token"), '_blank')
        return;
      }
      if (val === "toAccount") {
        document.location.href = "https://user.wesitrans.com/account"
        return;
      }
      if (val === "toOrder") {
        window.open("https://user.wesitrans.com/order", '_blank')
        return;
      }
      if (val === 'toTransPro') {
        this.$router.push('/');
        return
      }
      if (val === "txtTrans") {
        document.location.href = 'https://wesitrans.com/translate?type=txt';
        return;
      }
      if (val === "docTrans") {
        document.location.href = 'https://wesitrans.com/translate?type=doc';
        return;
      }
      if (val === "mediaTrans") {
        this.openNewMessage()
        return;
      }
      if (val === "imgTrans") {
        this.openNewMessage()
        return;
      }
      if (val === "xlsTrans") {
        document.location.href = 'https://wesitrans.com/translate?type=more';
        return;
      }
      if (val === "musicTrans") {
        this.openNewMessage()
        return;
      }
      if (val === "standLangSet") {
        this.openNewMessage()
        return;
      }
      if (val === "convertDoc") {
        document.location.href = 'https://wesitrans.com/convert';
        return;
      }
      if (val === "imgTrans") {
        document.location.href = 'https://wesitrans.com/translate?type=img';
        return;
      }
      if (val === "mySelfDoc") {
        document.location.href = 'https://wesitrans.com/list-pro';
        return;
      }
      let request = outRequest;
      if (val === undefined || val === "" || val === null || val === {}) {
        request.setName(this.userId)
      } else {
        request.setName(val)
      }
      const TOKEN_KEY = 'token';
      authServiceClient.logOut(request, {
        [TOKEN_KEY]: localStorage.getItem(TOKEN_KEY)
      }).then(res => {
        this.toPath("/index")
      }).catch(err => {
        localStorage.removeItem("token")
        localStorage.removeItem("auth-context")
        this.userInfo = "";
        this.showRoaming = false
        this.toPath("/index")
      });
    },
    openNewMessage() {
      this.$alert('服务升级中，敬请期待', '产品升级', {
        confirmButtonText: '确定',
      });
    },
  }
}
</script>

<style lang="less" scoped>
.main-index {
  min-width: 768px;
  width: 100%;

  .header {
    width: 100%;
    height: 88px;
    background: #E6E6E6;
    display: flex;

    .title_hide {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      background: #e6e6e6;
    }

    .login {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      align-items: center;
      display: flex;
      height: 100%;
      margin-left: 33px;
    }

    .selected {
      position: fixed;
      width: 201px;
      height: 372px;
      background: #FFFFFF;
      box-shadow: 0px 5px 10px 0px rgba(116, 114, 114, 0.51);
      z-index: 100;
      top: 90px;
      right: 31px;
      text-align: center;

      div {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 72px;
      }
    }

    .select {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      margin-right: 30px;

      .select-option {
        width: 48px;
        height: 11px;
        display: flex;

        div {
          height: 11px;
          width: 11px;
          background: #333333;
          margin-left: 5px;
          border-radius: 50%;
        }
      }

    }
  }

  .main-foot {
    height: 149px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 667px;
      height: 64px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #00233E;
      line-height: 36px;
    }
  }
}

.header-v2 {
  display: flex;
  width: 1200px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  .text_1 {
    width: 182px;
    height: 28px;
    overflow-wrap: break-word;
    color: #333333;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    line-height: 24px;
    margin: 0 0 0 119px;
    display: flex;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40px;
      cursor: pointer;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #333333;
    }
  }

  .header-left {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
  }

  .header-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .flex-row {
      display: flex;
      flex-direction: row;
    }
    .thumbnail_1 {
      width: 14px;
      height: 14px;
      margin: 6px 0 0 20px;
    }
    .text_2 {
      max-width: 80px;
      height: 24px;
      overflow-wrap: break-word;
      color: #666666;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      line-height: 24px;
      margin: 7px 0 0 8px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


.header {
  display: flex;
  width: 1200px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 80px;

  .header-left {
    display: flex;
    width: 135px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .header-right {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .title_hide {

    }

    div:nth-child(3) {
      height: 30px;
      width: 68px;
      background-color: #063F95;
      border-radius: 3px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-right: 10px;
      cursor: pointer;
    }

    div:nth-child(4) {
      height: 10px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-right: 10px;
      margin-left: 10px;
      cursor: pointer;
    }

    div:nth-child(5) {
      height: 10px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #DDDDDD;
      cursor: pointer;
    }

    div:nth-child(7) {
      height: 10px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }

    div:nth-child(6) {
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      margin-right: 10px;

      .el-button--text {
        color: #333333;
      }
    }

    div:nth-child(3):hover {
      background-color: #1565dc;
    }
  }
}

.line-hr {
  display: flex;
  width: 100%;
  height: 1px;
  background: #DDDDDD;
  border: 0;
}
</style>