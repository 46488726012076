<template>
  <div>
    <div v-if="!hasH5" class="main-content">
      <div class="breadcrumb ">
        <span @click="toPath('/')"> 首页</span> <span style="height: 17px;"><el-icon><d-arrow-right/></el-icon></span>
        <span @click="this.$router.push('/')"> 人工翻译</span><span style="height: 17px;"><el-icon><d-arrow-right/></el-icon></span>
        <span> 专业翻译</span>
      </div>
      <div class="main-zy">
        <div class="step">
          <div class="imgs">
            <div v-if="active>=0"><img src="../assets/v2/doc/img/zy-tj.jpg" alt=""></div>
            <div v-if="active<1"><img src="../assets/zy-kf.png" alt=""></div>
            <div v-if="active>=1"><img src="../assets/zy-kf-ok.png" alt=""></div>
            <div v-if="active<2"><img src="../assets/zy-zf.png" alt=""></div>
            <div v-if="active>=2"><img src="../assets/zy-zf-ok.png" alt=""></div>
            <div v-if="active<3"><img src="../assets/zy-wc.png" alt=""></div>
            <div v-if="active>=3"><img src="../assets/zy-wc-ok.png" alt=""></div>
          </div>
          <div class="imgs-lines">
            <div class="line-ok">
              <div></div>
            </div>
            <div :class="active>=1?'line-ok':'line'">
              <div></div>
            </div>
            <div :class="active>=2?'line-ok':'line'">
              <div></div>
            </div>
            <div :class="active>=3?'line-ok':'line'">
              <div></div>
            </div>
          </div>
          <el-steps class="steps" :active="active" align-center>
            <el-step title="提交订单"></el-step>
            <el-step title="等待客服沟通"></el-step>
            <el-step title="确认并支付订单"></el-step>
            <el-step title="获得润色文稿"></el-step>
          </el-steps>
        </div>
        <div class="step2" style="padding-bottom: 40px;">
          <div class="kuai"></div>
          <div class="txwdxq">填写文档需求</div>
          <div class="money-info" @click="moneyDialogVisible=true"><img src="../assets/money-info.png" alt=""/>价格服务说明
          </div>
        </div>
        <div class="step-content">
          <span class="font-warn">＊</span><span>选择语言</span>
          <div class="language">
            <el-select :class="languageActive" v-model="source" placeholder="" size="small">
              <el-option
                  v-for="item in optionsSource"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="lanuage-line"></div>
            <el-select :class="languageActive" v-model="target" placeholder="" size="small">
              <el-option
                  v-for="item in optionsTarget"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <span class="font-tip">注：目前仅支持中文与其他语言互译</span>
        </div>
        <div class="step-option">
          <span class="font-warn">＊</span><span>翻译类型</span>
          <div class="step-option-card">
            <div @click="biaozhunmoney==='暂不支持'?'':changes(1)"
                 :style="biaozhunmoney==='暂不支持'?'background: #f5f5f5':''"
                 :class="changeActive===1?`check-card ${errorActive}`:`${errorActive}`" class="card">
              <div class="bj-card">标准级翻译</div>
              <div>
                <img src="../assets/v2/doc/img/bj-card.jpg" alt="">
              </div>
              <div class="bj-font">
                <span>适合非紧急的日常沟通、 资料阅读等内容</span>
              </div>
              <div class="bj-line"></div>
              <div class="bj-font2" v-show="biaozhunmoney!=='暂不支持'">
                ￥ {{ biaozhunmoney }} <span>/千字</span>
              </div>
              <div class="bj-font2" v-show="biaozhunmoney==='暂不支持'">
                <span style="font-size: 18px">{{ biaozhunmoney }}</span>
              </div>
              <div class="bj-font3">
                无审校
              </div>
              <div class="bj-button">
                <div>选择</div>
              </div>
            </div>
            <div @click="yingyongmoney==='暂不支持'?'':changes(2)" :style="yingyongmoney==='暂不支持'?'background: #f5f5f5':''"
                 :class="changeActive===2?'check-card':''" class="card">
              <div class="bj-card">应用级翻译</div>
              <div>
                <img src="../assets/v2/doc/img/zy-yy.jpg" alt="">
              </div>
              <div class="bj-font">
                <span> 适合较为专业的内容 （内部文档、商务交流等）</span>
              </div>
              <div class="bj-line"></div>
              <div class="bj-font2" v-show="yingyongmoney!=='暂不支持'">
                ￥ {{ yingyongmoney }} <span>/千字</span>
              </div>
              <div class="bj-font2" v-show="yingyongmoney==='暂不支持'">
                <span style="font-size: 18px">{{ yingyongmoney }}</span>
              </div>
              <div class="bj-font3">
                翻译+审校质检
              </div>
              <div class="bj-button">
                <div>选择</div>
              </div>
            </div>
            <div @click="muyumoney==='暂不支持'?'':changes(3)"
                 :style="muyumoney==='暂不支持'?'background: #f5f5f5':''" :class="changeActive===3?'check-card':''"
                 class="card">
              <div class="bj-card">母语级翻译</div>
              <div>
                <img src="../assets/v2/doc/img/my-card.jpg" alt="">
              </div>
              <div class="bj-font">
                <span> 适合地道性要求高的内容 （演讲稿、涉外信函、出海产品介绍等）</span>
              </div>
              <div class="bj-line"></div>
              <div class="bj-font2" v-show="muyumoney!=='暂不支持'">
                ￥ {{ muyumoney }} <span>/千字</span>
              </div>
              <div class="bj-font2" v-show="muyumoney==='暂不支持'">
                <span style="font-size: 18px">{{ muyumoney }}</span>
              </div>
              <div class="bj-font3">
                翻译+母语审校质检
              </div>
              <div class="bj-button">
                <div>选择</div>
              </div>
            </div>
            <div @click="zhuanjiamoney==='暂不支持'?'':changes(4)"
                 :style="zhuanjiamoney==='暂不支持'?'background: #f5f5f5':''"
                 :class="changeActive===4?'check-card':''" class="card">
              <div class="bj-card">专家级翻译</div>
              <div>
                <img src="../assets/v2/doc/img/zy-zi-card.jpg" alt="">
              </div>
              <div class="bj-font">
                <span>  适合严谨性要求高的内容 （书稿出版、论文发表、政务稿件等）</span>
              </div>
              <div class="bj-line"></div>
              <div class="bj-font2" v-show="zhuanjiamoney!=='暂不支持'">
                ￥ {{ zhuanjiamoney }} <span>/千字</span>
              </div>
              <div class="bj-font2" v-show="zhuanjiamoney==='暂不支持'">
                <span style="font-size: 18px">{{ zhuanjiamoney }}</span>
              </div>
              <div class="bj-font3">
                翻译+母语审校+定稿质检
              </div>
              <div class="bj-button">
                <div>选择</div>
              </div>
            </div>
          </div>
        </div>
        <div class="step-tip">注：以300字中文字符数（不计空格）/非中文单词数为基础起计价，不足300字按照300字收取</div>
        <div class="step-upload">
          <span class="font-warn" style="height: 46px;display: flex;align-items: center;">＊</span><span
            style="height: 46px;display: flex;align-items: center;">翻译内容</span>
          <el-upload auto-upload :limit="10"
                     :with-credentials="true"
                     action="/pro/uploadFiles"
                     :file-list="fileList"
                     :on-error="onError"
                     :on-change="handleChange"
                     :on-success="onSuccess"
                     :before-upload="beforeUpload"
                     :on-exceed="handleExceed"
                     :on-remove="handleRemove"
                     accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .ppt, .pptx"
          >
            <div class="upload-button" :class="errUpload">
              <span>上传文档</span>
            </div>
          </el-upload>
          <div class="upload-button-tip">
            <span>请上传ppt/pptx、pdf、txt、xls/xlsx、doc /docx格式的文档，最多10个文档，单个大小不超过 10MB</span>
          </div>
        </div>
        <div class="step-desc">
          <span>备注需求</span>
          <el-input v-model="comment" placeholder="可考虑提供的说明信息：1.参考资料：如网站链接、截图或其他文档；2.使用场合：如将用于给最重要的客户做演讲等。"/>
        </div>
        <div class="step2">
          <div class="kuai"></div>
          <div class="txwdxq">填写联系方式</div>
        </div>
        <div class="step-info">
          <span class="font-warn">＊</span><span>联系电话</span>
          <el-input @blur="emailMobile" :class="errMobile" v-model="mobile" placeholder="用于客服将联系您沟通报价和返稿时间"/>
          <el-checkbox v-model="receivesms">免费获取短信，随时提醒订单状态</el-checkbox>
        </div>
        <div class="step-info" style="margin-top: 18px">
          <span class="font-warn">＊</span><span>电子邮箱</span>
          <el-input @blur="emailBlur" :class="errEmail" v-model="email" placeholder=""/>
        </div>
        <div class="step2" style="margin-top: 50px">
          <div class="kuai"></div>
          <div class="txwdxq">确认订单信息</div>
        </div>
        <div class="step-submit">
          <span class="ddxx">订单信息</span>
          <div class="files">
          <span style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            文件 <span style="color:#F0833C;">{{ fileList.length }}</span> <span style="color: #333333">个</span>
          </span>
          </div>
          <div class="files">
          <span style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            <span style="padding-right: 15px;">语向</span>    <span style="color: #333333">{{
              sourceName
            }}  >  {{ targetName }}</span>
          </span>
          </div>
          <div class="files">
          <span style="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            <span style="padding-right: 15px;">返稿时间</span>    <span @click="centerDialogVisible = true"
                                                                    style="cursor: pointer; color: #333333">待评估<img
              src="../assets/dpg.png" style="padding-left: 5px;" alt="待评估"></span>
          </span>
          </div>
        </div>
        <div class="step-proto">
          <el-checkbox :class="errProto" v-model="proto">我已阅读并同意
            <span style="color: #3E6D77"
                  @click="openAgreement()">《人工翻译平台服务协议》</span>
          </el-checkbox>
        </div>
        <div class="step-submit-info" :style="message===''?'margin-bottom: 48px;':''">
          <div class="step-submit-button" @click="submitOrder">
            <span>提交订单</span>
          </div>
          <span style="margin-left:10px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
          翻译完成后请在“<span style="color: #3E6D77;cursor: pointer" @click="toList">历史文档</span>”中查看结果</span>
        </div>
        <div class="step-submit-message" v-if="message!==''">
          <span>{{ message }}</span>
        </div>
      </div>
      <el-dialog top="5px" custom-class="dialogClass" v-model="centerDialogVisible" title="预计返稿时间" width="1140px"
                 center>
        <div class="dialogClass-content">
          <div>
            <div class="dialog-tips" style="margin-right: 20px;"><span>译员正常处理速度</span></div>
            <el-table border :header-row-class-name="'dialog-tip-header'" :data="tableData1"
                      style="width:521px;height: 580px;" :span-method="objectSpanMethod">
              <el-table-column prop="language" label="语言方向" width="173"/>
              <el-table-column prop="level" label="级别" width="173"/>
              <el-table-column prop="sudu" label="正常处理速度" width="173"/>
            </el-table>
          </div>
          <div>
            <div class="dialog-tips"><span>不足一天工作量时间速度预估</span></div>
            <el-table border :header-align="'center'" :header-row-class-name="'dialog-tip-header'" :data="tableData2"
                      style="width:521px;height: 580px;" :span-method="objectSpanMethod">
              <el-table-column prop="language" label="语言方向" width="173"/>
              <el-table-column prop="level" label="级别" width="173"/>
              <el-table-column prop="sudu" label="正常处理速度" width="173"/>
            </el-table>
          </div>
        </div>
        <div class="pginfo">
          <div style="padding: 13px;line-height: 2.5;">
            <div>说明：</div>
            <div>1.译员工作时间：周一至周日8：30-24：00（重大节日除外）</div>
            <div>2.返稿时间节点：每天12:00、15:00、18:00</div>
          </div>
        </div>
        <template #footer>
      <span class="dialog-footer">
      </span>
        </template>
      </el-dialog>
      <el-dialog top="5px" custom-class="payTipClass3" v-model="moneyDialogVisible" title="" width="1230px"
                 center>
        <div>
          <img src="../assets/价格说明表.jpg" alt="价格说明表" width="1136" height="1809">
        </div>
        <template #footer>
      <span class="dialog-footer">
      </span>
        </template>
      </el-dialog>
    </div>
    <div v-if="hasH5" class="trans-doc-main">
      <div class="trans-doc-main-head">
        <div class="trans-doc-main-head-active">
          <div class="step">
            <div class="imgs">
              <div class="imgs-active" v-if="active>=0"><img src="../assets/trans-doc-active.png" alt=""></div>
              <div class="imgs-active" v-if="active<1">
                <div>
                  <img src="../assets/trans-doc-gt.png" alt="">
                </div>
              </div>
              <div class="imgs-active" v-if="active<2">
                <div>
                  <img src="../assets/trans-doc-money.png" alt="">
                </div>
              </div>
              <div class="imgs-active" v-if="active<3">
                <div>
                  <img src="../assets/trans-doc-get.png" alt="">
                </div>
              </div>
            </div>
            <div class="imgs-lines">
              <div class="line-ok">
                <div></div>
              </div>
              <div :class="active>=1?'line-ok':'line'">
                <div></div>
              </div>
              <div :class="active>=2?'line-ok':'line'">
                <div></div>
              </div>
              <div :class="active>=3?'line-ok':'line'">
                <div></div>
              </div>
            </div>
            <el-steps class="steps" :active="active" align-center>
              <el-step title="提交订单"></el-step>
              <el-step title="等待客服沟通"></el-step>
              <el-step title="确认并支付订单"></el-step>
              <el-step title="获得润色文稿"></el-step>
            </el-steps>
          </div>
        </div>
      </div>
      <div class="trans-doc-main-fg">
      </div>
      <div class="trans-doc-main-info">
        <div class="step2" style="padding-bottom: 34px;">
          <div class="kuai"></div>
          <div class="txwdxq">填写文档需求</div>
          <div class="money-info" @click="toPath2('/transProPrice')">
            <img src="../assets/money-info.png" width="20" alt=""/>价格服务说明
          </div>
        </div>
        <div class="step-content">
          <span class="font-warn-h5">＊</span><span>选择语言</span>
          <div class="language">
            <el-select popper-class="popper-class" :class="languageActive" v-model="source" placeholder="" size="small">
              <el-option
                  v-for="item in optionsSource"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="lanuage-line"></div>
            <el-select popper-class="popper-class" :class="languageActive" v-model="target" placeholder="" size="small">
              <el-option
                  v-for="item in optionsTarget"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="trans-doc-main-font-tip">
          <span class="font-tip">注：目前仅支持中文与其他语言互译</span>
        </div>
        <div class="step-option">
          <span class="font-warn-h5">＊</span><span>翻译类型</span>
          <div class="step-option-card">
            <div class="step-option-card-cloum">
              <div @click="biaozhunmoney==='暂不支持'?'':changes(1)"
                   :style="biaozhunmoney==='暂不支持'?'background: #f5f5f5':''"
                   :class="changeActive===1?`check-card ${errorActive}`:`${errorActive}`" class="card">
                <div class="bj-card">标准级翻译</div>
                <div>
                  <img src="../assets/bj-card.png" alt="">
                </div>
                <div class="bj-font">
                  <span>适合非紧急的日常沟通、 资料阅读等内容</span>
                </div>
                <div class="bj-line" style="margin-top: -14px;"></div>
                <div class="bj-font2" v-show="biaozhunmoney!=='暂不支持'">
                  ￥ {{ biaozhunmoney }} <span>/千字</span>
                </div>
                <div class="bj-font2" v-show="biaozhunmoney==='暂不支持'">
                  <span style="font-size: 18px">{{ biaozhunmoney }}</span>
                </div>
                <div class="bj-font3">
                  无审校
                </div>
                <div class="bj-button">
                  <div>选择</div>
                </div>
              </div>
              <div @click="yingyongmoney==='暂不支持'?'':changes(2)"
                   :style="yingyongmoney==='暂不支持'?'background: #f5f5f5':''"
                   :class="changeActive===2?'check-card':''" class="card">
                <div class="bj-card">应用级翻译</div>
                <div>
                  <img src="../assets/zy-yy.png" alt="">
                </div>
                <div class="bj-font">
                  <span> 适合较为专业的内容 （内部文档、商务交流等）</span>
                </div>
                <div class="bj-line" style="margin-top: -14px;"></div>
                <div class="bj-font2" v-show="yingyongmoney!=='暂不支持'">
                  ￥ {{ yingyongmoney }} <span>/千字</span>
                </div>
                <div class="bj-font2" v-show="yingyongmoney==='暂不支持'">
                  <span style="font-size: 18px">{{ yingyongmoney }}</span>
                </div>
                <div class="bj-font3">
                  翻译+审校质检
                </div>
                <div class="bj-button">
                  <div>选择</div>
                </div>
              </div>
            </div>
            <div class="step-option-card-cloum">
              <div @click="muyumoney==='暂不支持'?'':changes(3)"
                   :style="muyumoney==='暂不支持'?'background: #f5f5f5':''" :class="changeActive===3?'check-card':''"
                   class="card">
                <div class="bj-card">母语级翻译</div>
                <div>
                  <img src="../assets/my-card.png" alt="">
                </div>
                <div class="bj-font">
                  <span> 适合地道性要求高的内容 （演讲稿、涉外信函、出海产品介绍等）</span>
                </div>
                <div class="bj-line"></div>
                <div class="bj-font2" v-show="muyumoney!=='暂不支持'">
                  ￥ {{ muyumoney }} <span>/千字</span>
                </div>
                <div class="bj-font2" v-show="muyumoney==='暂不支持'">
                  <span style="font-size: 18px">{{ muyumoney }}</span>
                </div>
                <div class="bj-font3">
                  翻译+母语审校质检
                </div>
                <div class="bj-button">
                  <div>选择</div>
                </div>
              </div>
              <div @click="zhuanjiamoney==='暂不支持'?'':changes(4)"
                   :style="zhuanjiamoney==='暂不支持'?'background: #f5f5f5':''"
                   :class="changeActive===4?'check-card':''" class="card">
                <div class="bj-card">专家级翻译</div>
                <div>
                  <img src="../assets/zy-zj-card.png" alt="">
                </div>
                <div class="bj-font">
                  <span>  适合严谨性要求高的内容 （书稿出版、论文发表、政务稿件等）</span>
                </div>
                <div class="bj-line"></div>
                <div class="bj-font2" v-show="zhuanjiamoney!=='暂不支持'">
                  ￥ {{ zhuanjiamoney }} <span>/千字</span>
                </div>
                <div class="bj-font2" v-show="zhuanjiamoney==='暂不支持'">
                  <span style="font-size: 18px">{{ zhuanjiamoney }}</span>
                </div>
                <div class="bj-font3">
                  翻译+母语审校+定稿质检
                </div>
                <div class="bj-button">
                  <div>选择</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step-tip">注：以300字中文字符数（不计空格）/非中文单词数为基础起计价，不足300字按照300字收取</div>
        <div class="step-upload">
          <span class="font-warn-h5" style="height: 46px;display: flex;align-items: center;">＊</span><span
            style="height: 46px;display: flex;align-items: center;">翻译内容</span>
          <el-upload auto-upload :limit="10"
                     :with-credentials="true"
                     action="/pro/uploadFiles"
                     :file-list="fileList"
                     :on-error="onError"
                     :on-change="handleChange"
                     :on-success="onSuccess"
                     :before-upload="beforeUpload"
                     :on-exceed="handleExceed"
                     :on-remove="handleRemove"
                     accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .ppt, .pptx"
          >
            <div class="upload-button" :class="errUpload">
              <span>上传文档</span>
            </div>
          </el-upload>
        </div>
        <div class="upload-button-tip">
          <span>请上传ppt/pptx、pdf、txt、xls/xlsx、doc /docx格式的文档，最多10个文档，单个大小不超过 10MB</span>
        </div>
        <div class="step-desc">
          <span>备注需求</span>
          <el-input type="textarea" :rows="9" max="9" min="9" v-model="comment" placeholder=""/>
        </div>
        <div class="step-desc-info">
          可考虑提供的说明信息：1.参考资料：如网站链接、 &#10; 截图或其他文档；2.使用场合：如将用于给最重要的客户做演讲等。
        </div>
        <div class="step2">
          <div class="kuai"></div>
          <div class="txwdxq">填写联系方式</div>
        </div>
        <div class="step-info">
          <span class="font-warn-h5">＊</span><span>联系电话</span>
          <el-input @blur="emailMobile" :class="errMobile" v-model="mobile" placeholder="用于客服将联系您沟通报价和返稿时间"/>
        </div>
        <div class="step-info" style="margin-top: 20px;">
          <el-checkbox v-model="receivesms">免费获取短信，随时提醒订单状态</el-checkbox>
        </div>
        <div class="step-info" style="margin-top: 18px">
          <span class="font-warn-h5">＊</span><span>电子邮箱</span>
          <el-input @blur="emailBlur" :class="errEmail" v-model="email" placeholder=""/>
        </div>
        <div class="step2" style="margin-top: 50px">
          <div class="kuai"></div>
          <div class="txwdxq">确认订单信息</div>
        </div>
        <div class="step-submit">
          <span class="ddxx">订单信息</span>
          <div class="files">
          <span style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            文件 <span style="color:#F0833C;">{{ fileList.length }}</span> <span style="color: #333333">个</span>
          </span>
          </div>
          <div class="files">
          <span style="font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            <span style="padding-right: 15px;">语向</span>    <span style="color: #333333">{{
              sourceName
            }}  >  {{ targetName }}</span>
          </span>
          </div>
        </div>
        <div class="step-submit" style="margin-top: 14px;margin-left: 168px;">
          <div class="files">
          <span style="display: flex;font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
            <span style="padding-right: 15px;">返稿时间</span>
            <span class="s-img" @click="toPath2('/transProTime')" style="cursor: pointer; color: #333333">
              待评估 <img src="../assets/dpg.png" style="padding-left: 23px;" alt="待评估"></span>
          </span>
          </div>
        </div>
        <div class="step-proto">
          <el-checkbox :class="errProto" v-model="proto">我已阅读并同意
            <span style="color: #3E6D77"
                  @click="openAgreement()">《人工翻译平台服务协议》</span>
          </el-checkbox>
        </div>
        <div class="step-submit-info" :style="message===''?'margin-bottom: 48px;':''">
          <div class="step-submit-button" @click="submitOrder">
            <span>提交订单</span>
          </div>
        </div>
        <div class="step-proto-tip">
        <span style="margin-left:10px;font-size: 24px;font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
          翻译完成后请在“<span style="color: #3E6D77;cursor: pointer" @click="toList">历史文档</span>”中查看结果</span>
        </div>
        <div class="step-submit-message" v-if="message!==''">
          <span style="font-size: 24px">{{ message }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocTrans",
  data() {
    return {
      hasH5: false,
      biaozhunmoney: 120,
      yingyongmoney: 260,
      muyumoney: 400,
      zhuanjiamoney: 580,
      message: '',
      fileList: [],
      tableData1: [
        {
          language: "中译英",
          level: "标准级",
          sudu: "3000字/天"
        },
        {
          language: "中译英",
          level: "应用级",
          sudu: "2500字/天"
        },
        {
          language: "中译英",
          level: "母语级",
          sudu: "1500字/天"
        },
        {
          language: "中译英",
          level: "专家级",
          sudu: "1000字/天"
        },
        {
          language: "英译中",
          level: "标准级",
          sudu: "1500词/天"
        },
        {
          language: "英译中",
          level: "应用级",
          sudu: "1250字/天"
        },
        {
          language: "英译中",
          level: "母语级",
          sudu: "750/天"
        },
        {
          language: "英译中",
          level: "专家级",
          sudu: "500/天"
        },
        {
          language: "中译外（其他16语种）",
          level: "应用级",
          sudu: "1500字/天"
        },
        {
          language: "外译中（其他16语种）",
          level: "应用级",
          sudu: "750/天"
        },
        {
          language: "外译中（其他16语种）",
          level: "母语级",
          sudu: "1000/天"
        },
      ],
      tableData2: [
        {
          language: "中译英",
          level: "标准级",
          sudu: "300字/小时"
        },
        {
          language: "中译英",
          level: "应用级",
          sudu: "250字/小时"
        },
        {
          language: "中译英",
          level: "母语级",
          sudu: "200字/小时"
        },
        {
          language: "中译英",
          level: "专家级",
          sudu: "130字/小时"
        },
        {
          language: "英译中",
          level: "标准级",
          sudu: "150词/小时"
        },
        {
          language: "英译中",
          level: "应用级",
          sudu: "130词/小时"
        },
        {
          language: "英译中",
          level: "母语级",
          sudu: "100词/小时"
        },
        {
          language: "英译中",
          level: "专家级",
          sudu: "60词/小时"
        },
        {
          language: "中译外（其他16语种）",
          level: "应用级",
          sudu: "200字/小时"
        },
        {
          language: "外译中（其他16语种）",
          level: "应用级",
          sudu: "100词/小时"
        },
        {
          language: "外译中（其他16语种）",
          level: "母语级",
          sudu: "130字/小时"
        },
      ],
      tableData3: [
        {
          language: "英语",
          level: "标准级",
          zhto: "180",
          ento: "300"
        },
        {
          language: "英语",
          level: "应用级",
          zhto: "260",
          ento: "400"
        },
        {
          language: "英语",
          level: "母语级",
          zhto: "400",
          ento: "700"
        },
        {
          language: "英语",
          level: "专家级",
          zhto: "580",
          ento: "700"
        },
        {
          language: "俄语",
          level: "应用级",
          zhto: "300",
          ento: "450"
        },
        {
          language: "法语",
          level: "应用级",
          zhto: "260",
          ento: "380"
        },
        {
          language: "西班牙语",
          level: "应用级",
          zhto: "300",
          ento: "520"
        },
        {
          language: "葡萄牙语",
          level: "应用级",
          zhto: "300",
          ento: "520"
        },
        {
          language: "日语",
          level: "应用级",
          zhto: "300",
          ento: "320"
        },
        {
          language: "韩语",
          level: "应用级",
          zhto: "300",
          ento: "320"
        },
        {
          language: "阿拉伯语",
          level: "应用级",
          zhto: "380",
          ento: "550"
        },
        {
          language: "德语",
          level: "应用级",
          zhto: "300",
          ento: "450"
        },
        {
          language: "土耳其语",
          level: "应用级",
          zhto: "600",
          ento: "1000"
        },
        {
          language: "意大利语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "荷兰语",
          level: "应用级",
          zhto: "600",
          ento: "1000"
        },
        {
          language: "泰语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "越南语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "马来语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "柬埔寨语",
          level: "应用级",
          zhto: "600",
          ento: "1000"
        },
        {
          language: "老挝语",
          level: "应用级",
          zhto: "400",
          ento: "600"
        },
        {
          language: "俄语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "法语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "西班牙语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "葡萄牙语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "日语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "韩语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "阿拉伯语",
          level: "母语级",
          zhto: "600",
        },
        {
          language: "德语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "土耳其语",
          level: "母语级",
          zhto: "1000",
        },
        {
          language: "意大利语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "荷兰语",
          level: "母语级",
          zhto: "1000",
        },
        {
          language: "泰语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "越南语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "马来语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "柬埔寨语",
          level: "母语级",
          zhto: "1000",
        },
        {
          language: "老挝语",
          level: "母语级",
          zhto: "700",
        },
      ],
      tableData4: [
        {
          language: "英文母语润色",
          level: "基础润色",
          zhto: "399",
        },
        {
          language: "英文母语润色",
          level: "高级润色",
          zhto: "699",
        },
      ],
      centerDialogVisible: false,
      moneyDialogVisible: false,
      proto: false,
      fileCount: 0,
      email: '',
      receivesms: false,
      mobile: '',
      comment: '',
      changeActive: 0,
      errorActive: '',
      errMobile: '',
      errUpload: '',
      errEmail: '',
      errProto: '',
      active: 0,
      languageActive: 'select',
      source: -1,
      target: -1,
      sourceName: "简体中文",
      targetName: "英语",
      options: [
        {id: 21, name: "简体中文"},
        {id: 22, name: "英语"},
        {id: 23, name: "德语"},
        {id: 24, name: "俄语"},
        {id: 25, name: "西班牙语"},
        {id: 26, name: "法语"},
        {id: 27, name: "阿拉伯语"},
        {id: 28, name: "日语"},
        {id: 29, name: "土耳其语"},
        {id: 30, name: "泰语"},
        {id: 31, name: "葡萄牙语"},
        {id: 32, name: "韩语"},
        {id: 43, name: "越南语"},
        {id: 44, name: "荷兰语"},
        {id: 51, name: "意大利语"},
        {id: 64, name: "马来语"},
        {id: 730, name: "柬埔寨语"},
        {id: 731, name: "老挝语"},
      ],
      optionsSource: [
        {id: -1, name: "源语言"}
      ],
      optionsTarget: [
        {id: -1, name: "目标语言"}
      ],
      fileUrl: [],
    }
  },
  watch: {
    proto(newVal, oldVal) {
      if (newVal) {
        this.errProto = ''
      }
    },
    mobile(newVal, oldVal) {
      if (newVal !== 0) {
        this.errMobile = ''
      }
    },
    email(newVal, oldVal) {
      if (newVal !== 0) {
        this.errEmail = ''
      }
    },
    changeActive(newVal, oldVal) {
      if (newVal !== 0) {
        this.errorActive = ''
      }
    },
    source(newVal, oldVal) {
      debugger
      if (newVal !== -1) {
        this.languageActive = 'select'
      }
      if (newVal === 21) {
        this.optionsTarget = this.options;
        this.optionsSource = [
          {id: 21, name: "简体中文"}
        ];
        this.target = 22;
      } else {
        this.optionsTarget = [
          {id: 21, name: "简体中文"}
        ]
        this.optionsSource = this.options;
        this.target = 21;
      }
      this.options.forEach(item => {
        if (item.id === newVal) {
          this.sourceName = item.name
        }
      })
      if (this.target !== -1 && this.source !== -1) {
        this.changeActive = 0
        this.biaozhunmoney = "暂不支持"
        this.yingyongmoney = "暂不支持"
        this.muyumoney = "暂不支持"
        this.zhuanjiamoney = "暂不支持"
        this.tableData3.forEach(item => {
          if (this.sourceName === item.language) {
            switch (item.level) {
              case "标准级":
                this.biaozhunmoney = newVal === 21 ? item.zhto : item.ento
                break
              case "应用级":
                this.yingyongmoney = newVal === 21 ? item.zhto : item.ento
                break
              case "母语级":
                if (item.ento) {
                  this.muyumoney = newVal === 21 ? item.zhto : item.ento
                }
                break
              case "专家级":
                this.zhuanjiamoney = newVal === 21 ? item.zhto : item.ento
                break
            }
          }
        })
      }
    },
    target(newVal, oldVal) {
      debugger
      if (newVal !== -1) {
        this.languageActive = 'select'
      }
      if (newVal === 21) {
        this.optionsTarget = [
          {id: 21, name: "简体中文"}
        ]
        this.optionsSource = this.options;
        this.source = 22;
      } else {
        this.optionsSource = [
          {id: 21, name: "简体中文"}
        ]
        this.optionsTarget = this.options;
        this.source = 21;
      }
      this.options.forEach(item => {
        if (item.id === newVal) {
          this.targetName = item.name
        }
      })
      if (this.target !== -1 && this.source !== -1&&this.target !== 21) {
        this.changeActive = 0
        this.biaozhunmoney = "暂不支持"
        this.yingyongmoney = "暂不支持"
        this.muyumoney = "暂不支持"
        this.zhuanjiamoney = "暂不支持"
        this.tableData3.forEach(item => {
          if (this.targetName === item.language) {
            switch (item.level) {
              case "标准级":
                this.biaozhunmoney = newVal === 21 ? item.ento : item.zhto
                break
              case "应用级":
                this.yingyongmoney = newVal === 21 ? item.ento : item.zhto
                break
              case "母语级":
                this.muyumoney = newVal === 21 ? item.ento : item.zhto
                break
              case "专家级":
                this.zhuanjiamoney = newVal === 21 ? item.ento : item.zhto
                break
            }
          }
        })
      }
    }
  },
  created() {
    this.hasH5 = this.isMobile()
    this.initLanguage()
  },
  methods: {
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    openAgreement() {
      window.open("/trans-pro-proto", "_bank");
      //this.$router.push('/trans-pro-proto')
    },
    emailMobile() {
      const flag = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!flag.test(this.mobile)) {
        this.message = "联系电话输入有误，请重新输入";
        this.errMobile = 'error'
        return
      }
      this.$http.post('/pro/checkPhone', {
        mobile: this.mobile
      }).then(res => {
        if (res.data && res.data === '权限不足') {
          this.$message.error("登录过期，请重新登录");
          this.$emit('changeActiveStep', new Date().getTime());
          return
        }
        if (res.data.code === "-1") {
          this.message = res.data.message;
          this.errMobile = 'error'
          return
        }
        this.message = '';
        this.errMobile = ''
      }).catch(err => {
        this.$message({
          message: err,
          type: 'error',
        })
      })
    },
    emailBlur() {
      const flag = /^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/
      if (!flag.test(this.email)) {
        this.message = "电子邮箱输入有误，请重新输入";
        this.errEmail = 'error'
      }
    },
    submitOrder() {
      if (this.source === -1 || this.target === -1) {
        this.message = "请选择语言";
        this.languageActive = 'select language-err '
        return
      }
      if (this.changeActive === 0) {
        this.message = "请选择翻译类型";
        this.errorActive = 'error'
        return
      }
      if (this.fileUrl.length === 0) {
        this.message = "请上传文档";
        this.errUpload = 'error'
        return
      }
      if (this.mobile === '') {
        this.message = "请填写联系电话";
        this.errMobile = 'error'
        return
      } else {
        const flag = /^[1][3,4,5,7,8,9][0-9]{9}$/
        if (!flag.test(this.mobile)) {
          this.message = "联系电话输入有误，请重新输入";
          this.errMobile = 'error'
          return;
        }
      }
      if (this.email === '') {
        this.message = "请填写电子邮箱";
        this.errEmail = 'error'
        return
      } else {
        const flag = /^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/
        if (!flag.test(this.email)) {
          this.message = "电子邮箱输入有误，请重新输入";
          this.errEmail = 'error'
          return;
        }
      }
      if (!this.proto) {
        this.message = "请阅读并同意协议";
        this.errProto = 'checkError'
        return
      }
      let loading = this.$loading({
        lock: true,
        text: '正在提交',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$http.post('/pro/createDocOrder', {
        sourceLanguage: this.source,
        targetLanguage: this.target,
        sourceLanguageName: this.sourceName,
        targetLanguageName: this.targetName,
        fileUrl: this.fileUrl,
        comment: (this.changeActive === 1 ? "【标准级】" : this.changeActive === 2 ? "【应用级】" : this.changeActive === 3 ? "【母语级】" : "【专家级】") + this.comment,
        mobile: this.mobile,
        email: this.email,
        receiveSms: this.receivesms,
        transType: (this.changeActive === 1 ? "【标准级】" : this.changeActive === 2 ? "【应用级】" : this.changeActive === 3 ? "【母语级】" : "专家级")
      }).then(res => {
        loading.close()
        if (res.data.code === "200") {
          this.$message({
            message: '提交订单成功',
            type: 'success',
          })
          setTimeout(() => {
            this.toList()
          }, 2000);
          return
        }
        this.$message({
          message: res.data.message,
          type: 'error',
        })
      }).catch(err => {
        loading.close()
        this.$message({
          message: '提交订单失败，请稍后再试！',
          type: 'error',
        })
      })
    },
    toList() {
      window.open("https://wesitrans.com/list-pro", "_self")
    },
    getIndex(val) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === val.name) return i;
      }
      return -1;
    },
    getFileUrlIndex(val) {
      for (let i = 0; i < this.fileUrl.length; i++) {
        if (this.fileUrl[i].indexOf(val.name)) return i;
      }
      return -1;
    },
    removeItem(val) {
      let index = this.getIndex(val);
      if (index > -1) {
        this.fileList.splice(index, 1);
      }
    },
    onError(err, file, fileList) {
      this.$message.error(err);
      this.removeItem(file)
    },
    onSuccess(response, file, fileList) {
      if (response === "权限不足") {
        this.$message.error("登录过期，请重新登录");
        this.$emit('changeActiveStep', new Date().getTime());
        return false;
      }
      if (response.code !== "200") {
        this.$message.error(response.message);
        this.removeItem(file)
        return false;
      }
      this.fileUrl.push(...response.result)
      return true;
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
      const extension =
          testmsg === "xls" ||
          testmsg === "xlsx" ||
          testmsg === "pdf" ||
          testmsg === "ppt" ||
          testmsg === "pptx" ||
          testmsg === "doc" ||
          testmsg === "docx" ||
          testmsg === "txt";
      if (!extension) {
        this.$message.error("暂不支持" + testmsg + "文件类型");
        this.removeItem(file)
        return false;
      }
      if (file.size === 0) {
        this.$message.error("您上传的文件内容为空！");
        return false;
      }
      const islimit = file.size < 20971520 / 2;
      if (!islimit) {
        this.$message.error("文件上传大小不能超过10M！");
        this.removeItem(file)
        return false;
      }
      this.errUpload = ''
      return extension && islimit;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      let index = this.getFileUrlIndex(file)
      if (index > -1) {
        this.fileUrl.splice(index, 1);
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前仅支持批量上传10个文件，您已经上传了${
              fileList.length
          } 个文件`
      )
      this.fileList = fileList.slice(0, 10)
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(0, 10)
    },
    objectZhuanYeMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (rowIndex < 4) {
          return {
            rowspan: 1,
            colspan: 1,
          }
        }
        if (rowIndex === 4) {
          return {
            rowspan: 16,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
      if (columnIndex === 1) {
        if (rowIndex > 3) {
          return {
            rowspan: 1,
            colspan: 1,
          }
        }
        if (rowIndex === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    objectMuYuClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1 || columnIndex === 2) {
        if (rowIndex === 15) {
          return "cell-big"
        }
      }
    },
    objectMuYuMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 16,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (rowIndex > 7) {
          return {
            rowspan: 1,
            colspan: 1,
          }
        }
        if (rowIndex % 4 === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    changes(val) {
      this.changeActive = val
    },
    toPath(url) {
      document.location.href = "https://wesitrans.com" + url
    },
    toPath2(url) {
      this.$router.push(url)
    },
    initLanguage() {
      this.optionsTarget = [
        {id: -1, name: "目标语言"}
      ]
      this.optionsSource = [
        {id: -1, name: "源语言"}
      ]
      this.optionsTarget.push(...this.options)
      this.optionsSource.push(...this.options)
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .payTipClass3 {
  background-color: #f0f8ff00;
  border: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
  cursor: pointer;
  width: 672px;

  .el-dialog__header {
    //display: none;
  }

  .el-dialog__body {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
  }

  .el-dialog__footer {
    display: none;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #eff1f5;
  }
}

.checkError {
  /deep/ .el-checkbox__inner {
    border: 1px solid red !important;
  }
}

.error {
  border: 1px solid red !important;
}

/deep/ .el-table {
  --el-table-border-color: #ffffff;
  --el-table-border: 1px solid #F5F5F5;
  --el-table-font-color: let(--el-text-color-regular);
  --el-table-header-font-color: let(--el-text-color-secondary);
  --el-table-row-hover-background-color: let(--el-background-color-base);
  --el-table-current-row-background-color: let(--el-color-primary-light-9);
  --el-table-header-background-color: let(--el-color-white);
  --el-table-fixed-box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  --el-table-background-color: let(--el-color-white);
  --el-table-expanded-cell-background-color: let(--el-color-white);

  .el-table__header {
    border-left: 2px solid #F5F5F5;
  }
}

/deep/ .dialogClassMoney {
  height: 1326px;

  /deep/ .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  .pginfo {
    width: 1057px;
    height: 124px;
    background: #F5F5F5;

    div {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 1.8;
    }
  }

  .money-desc {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin-left: 82px;
    margin-bottom: 10px;
    margin-top: -10px;
    width: 1140px;
  }

  .dialogClass-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .cell-big {
      height: 288px;
      vertical-align: baseline;
    }

    /deep/ .cell {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #333333;
      text-align: center;
    }

    .dialog-tips2 {
      width: 422px;
      height: 46px;
      background: #D3DEE8;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2px;

      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .dialog-tips {
      width: 615px;
      height: 46px;
      background: #D3DEE8;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2px;

      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .dialog-tip-header {
      width: 520px;
      height: 40px;
      background: #F5F5F5;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-left: 2px solid #F5F5F5;

      .is-leaf {
        background: #F5F5F5;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
  }

}

/deep/ .dialogClass {
  height: 886px;

  /deep/ .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  .pginfo {
    width: 1062px;
    height: 137px;
    background: #F5F5F5;

    div {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #333333;
    }
  }

  .money-desc {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin-left: 14px;
    margin-bottom: 10px;
    margin-top: -10px;
  }

  .dialogClass-content {
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/ .cell {
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #333333;
      text-align: center;
    }

    .dialog-tips {
      width: 520px;
      height: 46px;
      background: #D3DEE8;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2px;

      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .dialog-tip-header {
      width: 520px;
      height: 40px;
      background: #F5F5F5;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .is-leaf {
        background: #F5F5F5;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
  }


}

.check-card {
  border: 1px solid #3E6D77 !important;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  cursor: pointer;
}

.language {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-warn {
  color: red;
  margin-left: 30px;
  margin-right: 8px;
  padding-top: 5px;
}

.main-content {
  display: flex;
  width: 1200px;
  flex-flow: column;

  .breadcrumb {
    margin-top: 22px;
    margin-bottom: 12px;
    align-items: flex-end;
    display: flex;
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #5A5A5B;
    cursor: pointer;

    span {
      padding-right: 5px;
    }

    span:nth-child(5) {
      color: #8A8989;
    }
  }

  .main-zy {
    width: 1201px;
    background: #FFFFFF;

    .step2 {
      padding-bottom: 20px;
      display: flex;

      .kuai {
        width: 5px;
        background: #999999;
        margin-left: 30px;
        margin-right: 8px;
      }

      .txwdxq {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .money-info {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        margin-right: 30px;
        cursor: pointer;

        img {
          margin-right: 6px;
        }
      }
    }

    .step {
      padding-bottom: 50px;

      .imgs {
        display: flex;

        div {
          flex: 1;
          padding-top: 20px;
          margin-bottom: -4px;
        }
      }

      .imgs-lines {
        display: flex;
        transform: translate(12.5%, 0px);

        .line-ok {
          flex: 1;

          div {
            width: 1px;
            height: 15px;
            background: #3E6D77;
          }
        }

        .line {
          flex: 1;

          div {
            width: 1px;
            height: 15px;
            background: #DDDDDD;
          }
        }
      }
    }

    .step span {
      padding-top: 25px;
      padding-bottom: 10px;
      font-weight: 700;
      font-size: 15px;
      display: flex;
      font-family: SourceHanSansCN-Bold;
    }

    .step .steps {

      /deep/ .el-step__title {
        height: 14px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }

      /deep/ .el-step__head.is-finish {
        .el-step__icon.is-text {
          width: 24px;
          height: 24px;
          background: #3E6D77;
          border: 3px solid #F5F5F5;
          border-radius: 50%;

          .el-step__icon-inner {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      /deep/ .el-step__head.is-process {
        .el-step__icon.is-text {
          width: 24px;
          height: 24px;
          background: #3E6D77;
          border: 3px solid #F5F5F5;
          border-radius: 50%;

          .el-step__icon-inner {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      /deep/ .el-step__icon.is-text {
        width: 24px;
        height: 24px;
        background: #DDDDDD;
        border: 3px solid #F5F5F5;
        border-radius: 50%;

        .el-step__icon-inner {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }

      /deep/ .el-step__line {
        background: #DDDDDD;
      }

      /deep/ .el-step.is-center .el-step__line {
        left: 10%;
        right: -90%;
      }

      /deep/ .el-step__head {
        color: #DDDDDD;
        border-color: #DDDDDD;
      }

    }

    .step-option {
      background-color: white;
      text-align: left;
      height: 325px;
      display: flex;
      align-items: flex-start;
      margin-top: 14px;

      .step-option-card {
        display: flex;

        .card {
          width: 195px;
          height: 307px;
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          margin-left: 20px;
          cursor: pointer;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;

          .bj-card {
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #2F2F2F;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0;
          }

          .bj-font {
            width: 159px;
            height: 52px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            text-align: center;
          }

          .bj-line {
            width: 98px;
            height: 1px;
            background: #DDDDDD;
            margin-top: 21px;
            margin-bottom: 14px;
          }

          .bj-font2 {
            height: 16px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #E55050;

            span {
              color: #999999;
              font-size: 14px;
            }
          }

          .bj-font3 {
            width: 162px;
            height: 16px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            margin-top: 8px;
            text-align: center;
          }

          .bj-button {
            width: 104px;
            height: 24px;
            border: 1px solid #3E6D77;
            border-radius: 10px;
            margin: 18px 0;
            text-align: center;

            div {
              height: 14px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3E6D77;
              line-height: 23px;
            }
          }
        }
      }
    }

    .step-upload {
      display: flex;
      margin-top: 20px;
      text-align: center;

      /deep/ .el-upload-list {
        text-align: left;
        padding-left: 20px;
      }

      /deep/ .el-upload-list__item-name {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .upload-button {
        width: 259px;
        height: 46px;
        background: #3E6D77;
        border-radius: 23px;
        margin-left: 20px;

        /deep/ .el-button--small {
          min-height: 44px;
          padding: 0;
          font-size: 18px;
          border-radius: calc(let(--el-border-radius-base) - 1px);
          width: 218px;
        }

        /deep/ .el-button--primary {
          --el-button-font-color: #ffffff;
          --el-button-background-color: #3E6D77;
          --el-button-border-color: #3E6D77;
          --el-button-hover-color: #3E6D77;
          --el-button-active-font-color: #3E6D77;
          --el-button-active-background-color: #3E6D77;
          --el-button-active-border-color: #3E6D77;
        }

        span {
          width: 70px;
          height: 18px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F5F5F5;
          line-height: 45px;
        }
      }

      .upload-button-tip {
        margin-left: 15px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #BBBBBB;
        height: 46px;
        display: flex;
        align-items: center;
      }
    }

    .step-desc {
      text-align: left;
      margin-left: 50px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 50px;

      span {
        width: 80px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }

      /deep/ .el-input__inner {
        width: 1053px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 3px;
      }
    }

    .step-submit-message {
      color: red;
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;
    }

    .step-submit-info {
      margin-left: 125px;
      display: flex;
      align-items: center;
      margin-top: 37px;

      .step-submit-button {
        width: 259px;
        height: 46px;
        background: #3E6D77;
        border-radius: 23px;
        cursor: pointer;

        span {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F5F5F5;
          line-height: 45px;
        }
      }
    }

    .step-proto {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 23px;
      text-align: left;
      margin-left: 110px;
      display: flex;
      align-items: center;

      /deep/ .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        margin-left: 15px;
      }

      /deep/ .el-checkbox__label {
        width: 100px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      /deep/ .el-checkbox__inner::after {
        height: 10px;
        left: 7px;
        top: 2px;
      }

      /deep/ .el-checkbox {
        --el-checkbox-checked-background-color: #3E6D77;
      }
    }

    .step-submit {
      text-align: left;
      margin-left: 50px;
      display: flex;
      align-items: center;

      .ddxx {
        width: 75px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }

      .files {
        display: flex;
        width: 210px;
        height: 39px;
        background: #F5F5F5;
        margin-right: 5px;
        align-items: center;
        justify-content: center;
      }
    }

    .step-info {
      display: flex;
      align-items: center;
      text-align: left;

      span:nth-child(2) {
        width: 62px;
        margin-right: 12px;
      }

      /deep/ .el-input {
        width: 395px;
      }

      /deep/ .el-input__inner {
        width: 395px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 3px;
      }

      /deep/ .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        margin-left: 15px;
      }

      /deep/ .el-checkbox__label {
        width: 210px;
        height: 15px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #BBBBBB;
      }

      /deep/ .el-checkbox__inner::after {
        height: 10px;
        left: 7px;
        top: 2px;
      }

      /deep/ .el-checkbox {
        --el-checkbox-checked-background-color: #3E6D77;
      }
    }

    .step-tip {
      height: 15px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #F0833C;
      text-align: left;
      margin-left: 130px;
    }

    .step-content {
      background-color: white;
      text-align: left;
      height: 60px;
      display: flex;
      align-items: center;

      .lanuage-line {
        width: 20px;
        height: 1px;
        background: #DDDDDD;
        margin: 0 11px;
      }

      .language-err {
        border: 1px solid red !important;
      }

      .select {
        width: 182px;
        height: 40px;
        background: #fff;
        border: 1px solid #eee;
        border-radius: 6px;

        /deep/ .el-input--small .el-input__inner {
          height: 40px;
          line-height: 40px;
        }

        /deep/ .el-input--small .el-input__icon {
          line-height: 40px;
        }
      }

      .select:nth-child(1) {
        margin-left: 20px;
      }

      .font-tip {
        margin-left: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #BBBBBB;
        line-height: 56px;
      }
    }
  }

}

.popper-class {
  .el-select-dropdown__item {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .el-select-dropdown__item.selected {
    color: rgba(0, 73, 185, 1);
  }
}

.font-warn-h5 {
  color: rgba(229, 80, 80, 1);
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}

.trans-doc-main {
  min-width: 765px;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;

  .trans-doc-main-head {
    width: 100%;
    height: 270px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .step {
      width: 768px;
      padding-bottom: 50px;

      .imgs {
        display: flex;

        .imgs-active {
          flex: 1;
          margin-bottom: -4px;
          align-items: center;
          display: flex;
          justify-content: center;

          div {
            width: 57px;
            height: 57px;
            background: #DDDDDD;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .imgs-lines {
        display: flex;
        transform: translate(12.5%, 4px);

        .line-ok {
          flex: 1;

          div {
            width: 1px;
            height: 15px;
            background: #3E6D77;
          }
        }

        .line {
          flex: 1;

          div {
            width: 1px;
            height: 15px;
            background: #DDDDDD;
          }
        }
      }
    }

    .step span {
      padding-top: 25px;
      padding-bottom: 10px;
      font-weight: 700;
      font-size: 15px;
      display: flex;
      font-family: SourceHanSansCN-Bold;
    }

    .step .steps {

      /deep/ .el-step__title {
        height: 14px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }

      /deep/ .el-step__head.is-finish {
        .el-step__icon.is-text {
          width: 34px;
          height: 34px;
          background: #3E6D77;
          border: 3px solid #F5F5F5;
          border-radius: 50%;

          .el-step__icon-inner {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      /deep/ .el-step__head.is-process {
        .el-step__icon.is-text {
          width: 34px;
          height: 34px;
          background: #3E6D77;
          border: 3px solid #F5F5F5;
          border-radius: 50%;

          .el-step__icon-inner {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      /deep/ .el-step__icon.is-text {
        width: 34px;
        height: 34px;
        background: #DDDDDD;
        border: 3px solid #F5F5F5;
        border-radius: 50%;

        .el-step__icon-inner {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }

      /deep/ .el-step__line {
        background: #DDDDDD;
      }

      /deep/ .el-step.is-center .el-step__line {
        left: 10%;
        right: -90%;
      }

      /deep/ .el-step__head {
        color: #DDDDDD;
        border-color: #DDDDDD;
      }

    }
  }

  .trans-doc-main-fg {
    height: 15px;
    background: #f5f5f5;
    width: 100%;
  }

  .trans-doc-main-info {
    width: 768px;
    background: #FFFFFF;

    .step2 {
      padding-bottom: 20px;
      margin-top: 24px;
      display: flex;
      align-items: center;

      .kuai {
        width: 5px;
        background: #999999;
        margin-left: 30px;
        margin-right: 8px;
        height: 22px;
      }

      .txwdxq {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 56px;
      }

      .money-info {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        margin-right: 30px;
        cursor: pointer;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;

        img {
          margin-right: 6px;
        }
      }
    }

    .step-option {
      background-color: white;
      text-align: left;
      height: 1047px;
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 56px;
      margin-left: 30px;

      .step-option-card {
        display: flex;
        flex-flow: column;

        .step-option-card-cloum {
          display: flex;
          margin-bottom: 17px;

          .card {
            width: 280px;
            height: 500px;
            background: #FFFFFF;
            border: 1px solid #DDDDDD;
            box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            margin-left: 20px;
            cursor: pointer;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            .bj-card {
              width: 150px;
              height: 29px;
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #2F2F2F;
              line-height: 56px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 15px 0;
            }

            .bj-font {
              height: 100px;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 36px;
              text-align: center;
            }

            .bj-line {
              width: 98px;
              height: 1px;
              background: #DDDDDD;
              margin-top: 21px;
              margin-bottom: 14px;
            }

            .bj-font2 {
              height: 27px;
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #E55050;
              line-height: 30px;

              span {
                color: #999999;
                font-size: 30px;
              }
            }

            .bj-font3 {
              height: 23px;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 30px;
              margin-top: 8px;
              text-align: center;
            }

            .bj-button {
              width: 191px;
              height: 45px;
              border: 1px solid #3E6D77;
              border-radius: 10px;
              margin: 26px 0;
              text-align: center;

              div {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #3E6D77;
                line-height: 38px;
              }
            }
          }
        }

      }
    }

    .step-upload {
      margin-left: 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 56px;
      display: flex;
      margin-top: 20px;
      text-align: center;

      /deep/ .el-upload-list {
        text-align: left;
        padding-left: 20px;
      }

      /deep/ .el-upload-list__item-name {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .upload-button {
        width: 336px;
        height: 60px;
        background: #3E6D77;
        border-radius: 30px;
        margin-left: 20px;

        /deep/ .el-button--small {
          min-height: 44px;
          padding: 0;
          font-size: 18px;
          border-radius: calc(let(--el-border-radius-base) - 1px);
          width: 218px;
        }

        /deep/ .el-button--primary {
          --el-button-font-color: #ffffff;
          --el-button-background-color: #3E6D77;
          --el-button-border-color: #3E6D77;
          --el-button-hover-color: #3E6D77;
          --el-button-active-font-color: #3E6D77;
          --el-button-active-background-color: #3E6D77;
          --el-button-active-border-color: #3E6D77;
        }

        span {
          width: 94px;
          height: 23px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F5F5F5;
          line-height: 48px;
        }
      }


    }

    .upload-button-tip {
      height: 60px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #BBBBBB;
      line-height: 36px;
      margin-left: 165px;
      display: flex;
      align-items: center;
      margin-top: 13px;
      text-align: initial;
    }

    .step-desc-info {
      width: 548px;
      height: 95px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #BBBBBB;
      line-height: 36px;
      margin-left: 170px;
      text-align: initial;
    }

    .step-desc {
      margin-top: 24px;
      height: 164px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 56px;
      text-align: left;
      margin-left: 50px;
      margin-bottom: 50px;
      display: flex;
      align-items: end;
      justify-content: center;

      span {
        width: 120px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-right: 20px;
      }

      /deep/ .el-textarea__inner {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 36px;
        width: 578px;
        height: 180px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 3px;
      }
    }

    .step-submit-message {
      color: red;
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;
    }

    .step-submit-info {
      margin-left: 125px;
      display: flex;
      align-items: center;
      margin-top: 37px;

      .step-submit-button {
        width: 336px;
        height: 60px;
        background: #3E6D77;
        border-radius: 30px;
        margin-left: 20px;
        cursor: pointer;
        text-align: center;

        span {
          width: 94px;
          height: 23px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F5F5F5;
          line-height: 52px;
        }
      }
    }

    .step-proto-tip {
      height: 70px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-top: 30px;
    }

    .step-proto {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 23px;
      text-align: left;
      margin-left: 110px;
      display: flex;
      align-items: center;

      /deep/ .el-checkbox__inner {
        width: 36px;
        height: 36px;
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        margin-left: 60px;
      }

      /deep/ .el-checkbox__label {
        width: 445px;
        height: 24px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      /deep/ .el-checkbox__inner::after {
        height: 10px;
        left: -10px;
        top: 2px;
      }

      /deep/ .el-checkbox {
        display: flex;
        align-items: center;
        --el-checkbox-checked-background-color: #3E6D77;
      }
    }

    .step-submit {
      text-align: left;
      margin-left: 50px;
      display: flex;
      align-items: center;

      .ddxx {
        width: 100px;
        height: 23px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-right: 18px;
      }

      .files {
        width: 285px;
        height: 50px;
        background: #F5F5F5;
        display: flex;
        margin-right: 5px;
        align-items: center;
        justify-content: center;

        .s-img {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .step-info {
      display: flex;
      align-items: center;
      text-align: left;
      height: 60px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 56px;
      margin-left: 30px;

      span:nth-child(2) {
        width: 111px;
        margin-right: 12px;
      }

      /deep/ .el-input {
        width: 577px;
      }

      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #3E6D77;
      }

      /deep/ .el-checkbox__inner::after {
        box-sizing: content-box;
        content: "";
        border: 1px solid #3E6D77;
        border-left: 0;
        border-top: 0;
        height: 7px;
        left: 4px;
        position: absolute;
        top: 1px;
        transform: rotate(45deg) scaleY(0);
        width: 3px;
        transition: transform .15s ease-in .05s;
        transform-origin: center;
      }

      /deep/ .el-input__inner {
        width: 577px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 3px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }

      /deep/ .el-checkbox__inner {
        width: 36px;
        height: 36px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        margin-left: 146px;
      }

      /deep/ .el-checkbox__label {
        width: 359px;
        height: 24px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #BBBBBB;
      }

      /deep/ .el-checkbox__inner::after {
        height: 10px;
        left: -10px;
        top: 2px;
      }

      /deep/ .el-checkbox {
        display: flex;
        align-items: center;
        --el-checkbox-checked-background-color: #3E6D77;
      }
    }

    .step-tip {
      width: 576px;
      height: 60px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #F0833C;
      line-height: 36px;
      text-align: left;
      margin-left: 168px;
      margin-bottom: 49px;
    }

    .trans-doc-main-font-tip {
      .font-tip {
        margin-left: 161px;
        width: 383px;
        height: 23px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #BBBBBB;
        line-height: 56px;
      }
    }

    .step-content {
      background-color: white;
      text-align: left;
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 56px;
      margin-left: 30px;

      .lanuage-line {
        width: 20px;
        height: 1px;
        background: #DDDDDD;
        margin: 0 11px;
      }

      .language-err {
        border: 1px solid red !important;
      }

      .select {
        width: 260px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 3px;

        /deep/ .el-input--small .el-input__inner {
          height: 48px;
          line-height: 40px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
        }

        /deep/ .el-input--small .el-input__icon {
          line-height: 40px;
        }

        /deep/ .el-input__suffix {
          height: 38px;

          .el-select .el-input .el-select__caret {
            font-size: 18px;
          }
        }
      }

      .select:nth-child(1) {
        margin-left: 20px;
      }


    }
  }
}
</style>