/**
 * @fileoverview gRPC-Web generated client stub for TranslatePdf
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.TranslatePdf = require('./translateService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.TranslatePdf.TranslateServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.TranslatePdf.TranslateServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.UploadRequest,
 *   !proto.TranslatePdf.UpLoadResponse>}
 */
const methodDescriptor_TranslateService_upload = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/upload',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.UploadRequest,
  proto.TranslatePdf.UpLoadResponse,
  /**
   * @param {!proto.TranslatePdf.UploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.UpLoadResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.UpLoadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.UpLoadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.upload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/upload',
      request,
      metadata || {},
      methodDescriptor_TranslateService_upload,
      callback);
};


/**
 * @param {!proto.TranslatePdf.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.UpLoadResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.upload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/upload',
      request,
      metadata || {},
      methodDescriptor_TranslateService_upload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.UploadRequest,
 *   !proto.TranslatePdf.UpLoadResponse>}
 */
const methodDescriptor_TranslateService_uploadImg = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/uploadImg',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.UploadRequest,
  proto.TranslatePdf.UpLoadResponse,
  /**
   * @param {!proto.TranslatePdf.UploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.UpLoadResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.UpLoadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.UpLoadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.uploadImg =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/uploadImg',
      request,
      metadata || {},
      methodDescriptor_TranslateService_uploadImg,
      callback);
};


/**
 * @param {!proto.TranslatePdf.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.UpLoadResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.uploadImg =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/uploadImg',
      request,
      metadata || {},
      methodDescriptor_TranslateService_uploadImg);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.UploadConvertPDFRequest,
 *   !proto.TranslatePdf.UploadConvertPDFResponse>}
 */
const methodDescriptor_TranslateService_uploadConvertPDF = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/uploadConvertPDF',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.UploadConvertPDFRequest,
  proto.TranslatePdf.UploadConvertPDFResponse,
  /**
   * @param {!proto.TranslatePdf.UploadConvertPDFRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.UploadConvertPDFResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.UploadConvertPDFRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.UploadConvertPDFResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.UploadConvertPDFResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.uploadConvertPDF =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/uploadConvertPDF',
      request,
      metadata || {},
      methodDescriptor_TranslateService_uploadConvertPDF,
      callback);
};


/**
 * @param {!proto.TranslatePdf.UploadConvertPDFRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.UploadConvertPDFResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.uploadConvertPDF =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/uploadConvertPDF',
      request,
      metadata || {},
      methodDescriptor_TranslateService_uploadConvertPDF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.FileListRequest,
 *   !proto.TranslatePdf.FileListResponse>}
 */
const methodDescriptor_TranslateService_fileList = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/fileList',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.FileListRequest,
  proto.TranslatePdf.FileListResponse,
  /**
   * @param {!proto.TranslatePdf.FileListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.FileListResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.FileListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.FileListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.FileListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.fileList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/fileList',
      request,
      metadata || {},
      methodDescriptor_TranslateService_fileList,
      callback);
};


/**
 * @param {!proto.TranslatePdf.FileListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.FileListResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.fileList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/fileList',
      request,
      metadata || {},
      methodDescriptor_TranslateService_fileList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.DeleteFileRequest,
 *   !proto.TranslatePdf.DeleteFileResponse>}
 */
const methodDescriptor_TranslateService_deleteFile = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/deleteFile',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.DeleteFileRequest,
  proto.TranslatePdf.DeleteFileResponse,
  /**
   * @param {!proto.TranslatePdf.DeleteFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.DeleteFileResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.DeleteFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.DeleteFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.DeleteFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.deleteFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/deleteFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_deleteFile,
      callback);
};


/**
 * @param {!proto.TranslatePdf.DeleteFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.DeleteFileResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.deleteFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/deleteFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_deleteFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.ReadingFileRequest,
 *   !proto.TranslatePdf.ReadingFileResponse>}
 */
const methodDescriptor_TranslateService_readingFile = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/readingFile',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.TranslatePdf.ReadingFileRequest,
  proto.TranslatePdf.ReadingFileResponse,
  /**
   * @param {!proto.TranslatePdf.ReadingFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.ReadingFileResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.ReadingFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ReadingFileResponse>}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.readingFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/TranslatePdf.TranslateService/readingFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_readingFile);
};


/**
 * @param {!proto.TranslatePdf.ReadingFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ReadingFileResponse>}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.readingFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/TranslatePdf.TranslateService/readingFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_readingFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.ReadingFileRequest,
 *   !proto.TranslatePdf.ReadingFileResponse>}
 */
const methodDescriptor_TranslateService_downLoadFile = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/downLoadFile',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.TranslatePdf.ReadingFileRequest,
  proto.TranslatePdf.ReadingFileResponse,
  /**
   * @param {!proto.TranslatePdf.ReadingFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.ReadingFileResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.ReadingFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ReadingFileResponse>}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.downLoadFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/TranslatePdf.TranslateService/downLoadFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_downLoadFile);
};


/**
 * @param {!proto.TranslatePdf.ReadingFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ReadingFileResponse>}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.downLoadFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/TranslatePdf.TranslateService/downLoadFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_downLoadFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.ReadingFileRequest,
 *   !proto.TranslatePdf.ReadingFileResponse>}
 */
const methodDescriptor_TranslateService_downLoadConvertFile = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/downLoadConvertFile',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.TranslatePdf.ReadingFileRequest,
  proto.TranslatePdf.ReadingFileResponse,
  /**
   * @param {!proto.TranslatePdf.ReadingFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.ReadingFileResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.ReadingFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ReadingFileResponse>}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.downLoadConvertFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/TranslatePdf.TranslateService/downLoadConvertFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_downLoadConvertFile);
};


/**
 * @param {!proto.TranslatePdf.ReadingFileRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ReadingFileResponse>}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.downLoadConvertFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/TranslatePdf.TranslateService/downLoadConvertFile',
      request,
      metadata || {},
      methodDescriptor_TranslateService_downLoadConvertFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.StatusRequest,
 *   !proto.TranslatePdf.StatusResponse>}
 */
const methodDescriptor_TranslateService_checkStatus = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/checkStatus',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.StatusRequest,
  proto.TranslatePdf.StatusResponse,
  /**
   * @param {!proto.TranslatePdf.StatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.StatusResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.StatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.StatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.StatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.checkStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkStatus',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkStatus,
      callback);
};


/**
 * @param {!proto.TranslatePdf.StatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.StatusResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.checkStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkStatus',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.DocumentRequest,
 *   !proto.TranslatePdf.DocumentResponse>}
 */
const methodDescriptor_TranslateService_getDocument = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/getDocument',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.DocumentRequest,
  proto.TranslatePdf.DocumentResponse,
  /**
   * @param {!proto.TranslatePdf.DocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.DocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.getDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/getDocument',
      request,
      metadata || {},
      methodDescriptor_TranslateService_getDocument,
      callback);
};


/**
 * @param {!proto.TranslatePdf.DocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.getDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/getDocument',
      request,
      metadata || {},
      methodDescriptor_TranslateService_getDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.UploadCountRequest,
 *   !proto.TranslatePdf.UploadCountResponse>}
 */
const methodDescriptor_TranslateService_checkUploadCountByDay = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/checkUploadCountByDay',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.UploadCountRequest,
  proto.TranslatePdf.UploadCountResponse,
  /**
   * @param {!proto.TranslatePdf.UploadCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.UploadCountResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.UploadCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.UploadCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.UploadCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.checkUploadCountByDay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkUploadCountByDay',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkUploadCountByDay,
      callback);
};


/**
 * @param {!proto.TranslatePdf.UploadCountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.UploadCountResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.checkUploadCountByDay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkUploadCountByDay',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkUploadCountByDay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.ReadDocumentRequest,
 *   !proto.TranslatePdf.ReadDocumentResponse>}
 */
const methodDescriptor_TranslateService_checkReadDocument = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/checkReadDocument',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.ReadDocumentRequest,
  proto.TranslatePdf.ReadDocumentResponse,
  /**
   * @param {!proto.TranslatePdf.ReadDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.ReadDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.ReadDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.ReadDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ReadDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.checkReadDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkReadDocument',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkReadDocument,
      callback);
};


/**
 * @param {!proto.TranslatePdf.ReadDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.ReadDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.checkReadDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkReadDocument',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkReadDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.UploadTipRequest,
 *   !proto.TranslatePdf.UploadTipResponse>}
 */
const methodDescriptor_TranslateService_getUploadTip = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/getUploadTip',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.UploadTipRequest,
  proto.TranslatePdf.UploadTipResponse,
  /**
   * @param {!proto.TranslatePdf.UploadTipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.UploadTipResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.UploadTipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.UploadTipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.UploadTipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.getUploadTip =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/getUploadTip',
      request,
      metadata || {},
      methodDescriptor_TranslateService_getUploadTip,
      callback);
};


/**
 * @param {!proto.TranslatePdf.UploadTipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.UploadTipResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.getUploadTip =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/getUploadTip',
      request,
      metadata || {},
      methodDescriptor_TranslateService_getUploadTip);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.SubmitRequest,
 *   !proto.TranslatePdf.SubmitResponse>}
 */
const methodDescriptor_TranslateService_submit = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/submit',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.SubmitRequest,
  proto.TranslatePdf.SubmitResponse,
  /**
   * @param {!proto.TranslatePdf.SubmitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.SubmitResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.SubmitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.SubmitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.SubmitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.submit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/submit',
      request,
      metadata || {},
      methodDescriptor_TranslateService_submit,
      callback);
};


/**
 * @param {!proto.TranslatePdf.SubmitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.SubmitResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.submit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/submit',
      request,
      metadata || {},
      methodDescriptor_TranslateService_submit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.SubmitConvertRequest,
 *   !proto.TranslatePdf.SubmitConvertResponse>}
 */
const methodDescriptor_TranslateService_submitConvert = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/submitConvert',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.SubmitConvertRequest,
  proto.TranslatePdf.SubmitConvertResponse,
  /**
   * @param {!proto.TranslatePdf.SubmitConvertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.SubmitConvertResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.SubmitConvertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.SubmitConvertResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.SubmitConvertResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.submitConvert =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/submitConvert',
      request,
      metadata || {},
      methodDescriptor_TranslateService_submitConvert,
      callback);
};


/**
 * @param {!proto.TranslatePdf.SubmitConvertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.SubmitConvertResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.submitConvert =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/submitConvert',
      request,
      metadata || {},
      methodDescriptor_TranslateService_submitConvert);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.QueryDocumentRequest,
 *   !proto.TranslatePdf.QueryDocumentResponse>}
 */
const methodDescriptor_TranslateService_queryDocument = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/queryDocument',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.QueryDocumentRequest,
  proto.TranslatePdf.QueryDocumentResponse,
  /**
   * @param {!proto.TranslatePdf.QueryDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.QueryDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.QueryDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.QueryDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.QueryDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.queryDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/queryDocument',
      request,
      metadata || {},
      methodDescriptor_TranslateService_queryDocument,
      callback);
};


/**
 * @param {!proto.TranslatePdf.QueryDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.QueryDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.queryDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/queryDocument',
      request,
      metadata || {},
      methodDescriptor_TranslateService_queryDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.PayRequest,
 *   !proto.TranslatePdf.PayResponse>}
 */
const methodDescriptor_TranslateService_checkPay = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/checkPay',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.PayRequest,
  proto.TranslatePdf.PayResponse,
  /**
   * @param {!proto.TranslatePdf.PayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.PayResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.PayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.PayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.PayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.checkPay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkPay',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkPay,
      callback);
};


/**
 * @param {!proto.TranslatePdf.PayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.PayResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.checkPay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkPay',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkPay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.ParentPowerRequest,
 *   !proto.TranslatePdf.ParentPowerResponse>}
 */
const methodDescriptor_TranslateService_checkParentPower = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/checkParentPower',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.ParentPowerRequest,
  proto.TranslatePdf.ParentPowerResponse,
  /**
   * @param {!proto.TranslatePdf.ParentPowerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.ParentPowerResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.ParentPowerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.ParentPowerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.ParentPowerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.checkParentPower =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkParentPower',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkParentPower,
      callback);
};


/**
 * @param {!proto.TranslatePdf.ParentPowerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.ParentPowerResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.checkParentPower =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkParentPower',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkParentPower);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.TransTextRequest,
 *   !proto.TranslatePdf.TransTextResponse>}
 */
const methodDescriptor_TranslateService_submitTransText = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/submitTransText',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.TransTextRequest,
  proto.TranslatePdf.TransTextResponse,
  /**
   * @param {!proto.TranslatePdf.TransTextRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.TransTextResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.TransTextRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.TransTextResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.TransTextResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.submitTransText =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/submitTransText',
      request,
      metadata || {},
      methodDescriptor_TranslateService_submitTransText,
      callback);
};


/**
 * @param {!proto.TranslatePdf.TransTextRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.TransTextResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.submitTransText =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/submitTransText',
      request,
      metadata || {},
      methodDescriptor_TranslateService_submitTransText);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TranslatePdf.CheckRoamingRequest,
 *   !proto.TranslatePdf.CheckRoamingResponse>}
 */
const methodDescriptor_TranslateService_checkRoaming = new grpc.web.MethodDescriptor(
  '/TranslatePdf.TranslateService/checkRoaming',
  grpc.web.MethodType.UNARY,
  proto.TranslatePdf.CheckRoamingRequest,
  proto.TranslatePdf.CheckRoamingResponse,
  /**
   * @param {!proto.TranslatePdf.CheckRoamingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TranslatePdf.CheckRoamingResponse.deserializeBinary
);


/**
 * @param {!proto.TranslatePdf.CheckRoamingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TranslatePdf.CheckRoamingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TranslatePdf.CheckRoamingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TranslatePdf.TranslateServiceClient.prototype.checkRoaming =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkRoaming',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkRoaming,
      callback);
};


/**
 * @param {!proto.TranslatePdf.CheckRoamingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TranslatePdf.CheckRoamingResponse>}
 *     Promise that resolves to the response
 */
proto.TranslatePdf.TranslateServicePromiseClient.prototype.checkRoaming =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TranslatePdf.TranslateService/checkRoaming',
      request,
      metadata || {},
      methodDescriptor_TranslateService_checkRoaming);
};


module.exports = proto.TranslatePdf;

