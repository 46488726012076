<template>
  <div>
    <div v-if="!hasH5" class="proto-content">
      <div class="proto-title">人工翻译平台服务协议</div>
      <div class="proto-one" style="margin-top: 40px"><span>1.</span><span>定义及解释</span></div>
      <div class="proto-one-content">
        <div>1、北京中外翻译咨询有限公司：以下简称“甲方”。</div>
        <div>2、用户：指符合本协议规定的条件，使用该人工翻译平台的单位或个人，以下简称“乙方”。</div>
        <div>3、翻译服务：指甲方按照本服务协议通过该人工翻译平台向乙方提供的专业翻译服务。翻译服务的所有权及运作权归甲方所有。</div>
        <div>4、翻译平台：为有专业翻译需求的用户提供人工翻译服务的媒介</div>
      </div>
      <div class="proto-one"><span>2.</span><span>协议的确认与接受</span></div>
      <div class="proto-one-content">
        <div>乙方理解并同意甲方在该平台上公布的《人工翻译平台服务协议》（即“本协议”）及其后续更新版本中的内容。乙方使用人工翻译平台专业翻译服务的行为将视为乙方对本协议全部内容和条款的接受。</div>
      </div>
      <div class="proto-one"><span>3.</span><span>人工翻译服务的内容</span></div>
      <div class="proto-one-content">
        <div>1、乙方通过人工翻译平台的相关页面，向甲方提交委托翻译服务请求。</div>
        <div>2、乙方提交翻译稿件：乙方通过人工翻译平台相关页面向甲方提交所需要翻译的稿件。</div>
        <div>3、字数统计：字数统计根据乙方提交翻译稿件使用 Microsoft Word “字数统计”功能确定，页眉、页脚、文本框、图片另行统计。</div>
        <div>4、服务费用：</div>
        <div style="margin-left: 20px;">a).专业翻译服务：甲方根据乙方对翻译内容用途、质量等级的要求，按照不同质量标准、不同语种进行收费。</div>
        <div> 收费标准如下：</div>
      </div>
      <div class="dialogClassMoney" style="margin-top: -16px;">
        <div class="dialogClass-content">
          <div>
            <img src="../assets/价格表.jpg" alt="价格表" width="1064" height="1539">
          </div>
        </div>
      </div>
      <div class="pginfo">
        <div style="margin-left: 92px">
          <div style="margin-top: 15px;margin-bottom: 18px;">温馨提示：</div>
          <div>
            <div>1、原文是中文、日文、韩文，按照word文档字数统计里“字符数（不计空格）”计算价格。</div>
            <div>2、原文是外文（除日文、韩文），按照外文单词数/字数计算价格。</div>
            <div>3、排版、打印、盖章等都是额外的服务，收费另计。</div>
            <div>4、不足300字按300字计价。</div>
            <div style="margin-left: 22px">b).英文母语润色</div>
            <div style="margin-left: 22px;margin-right: 75px">母语润色服务，对具有一定基础、可读性的英文稿件，提供在原文基础上进行专业母语润色、审校、质检服务。
              由专业学科母语编辑提供稿件润色服务，改善语言质
              量，提升表达效果，可助力SCI论文成功发表。甲方根据乙方稿件字数按照不同的服务级别进行收费，收费标准如下：基础润色399元/千词，高级润色699元/千词。
            </div>
            <div>5、交稿时间：</div>
            <div style="margin-left: 22px;margin-right: 75px">
              a).专业翻译：对于2000字以下的标准级翻译稿件，一般情况下，甲方收到乙方服务费用后1个工作日内交稿；甲方有权根据稿件难易程度等因素调整交稿时间，并通
              过双方协商确认。
            </div>
            <div style="margin-left: 22px;margin-right: 75px">
              b).英文母语润色：基础润色处理速度为1000词/天，高级润色处理速度为750词/天。甲方有权根据稿件难易程度等因素调整交稿时间，并通过双方协商确认。
            </div>
            <div>6、交稿方式：甲方将在人工翻译平台网站“历史订单”中返回译文，</div>
            <div style="margin-right: 75px">7、售后服务：甲方交稿3日后不支持退款，日常快译交稿后72小时内、专业翻译交稿后15日内提供免费修改服务、英文母语润色基础润色15日内提供免费
              修改1次，高
            </div>
            <div style="margin-left: 22px;"> 级润色90日内提供免费修改1次。由于翻译质量造成的问题及下错订单等情况，可与客服协商退款，其他情况仅支持免费修改。</div>
            <div style="margin-right: 75px"> 8、修改须知：如交稿后，乙方对原文进行修改，则修改内容另行计费；修改文字超过20%，按照全文重新翻译计算。如在翻译过程中更换或修改原文，已经完成部分要
            </div>
            <div style="margin-left: 22px">计入稿费。
            </div>
          </div>
        </div>
      </div>
      <div class="proto-one" style="margin-top: 38px"><span>4.</span><span>付款及发票</span></div>
      <div class="proto-one-content">
        <div>1、乙方应按照甲方网站提示，在提交所需翻译的稿件之前通过翻译平台向甲方全额支付相关翻译费用。</div>
        <div>2、付款方式：乙方通过翻译平台提供的在线支付方式一次性支付所有翻译费用。具体付款方式以平台提供为准。</div>
        <div>3、发票：乙方支付款项对应的发票由甲方提供。</div>
      </div>
      <div class="proto-one"><span>5.</span><span>双方权利义务</span></div>
      <div class="proto-one-content">
        <div>1、甲方承诺按照中华人民共和国国家标准GB/T 19682-2005相关规定完成稿件翻译服务。</div>
        <div>2、翻译内容由乙方提供，如涉及图片、照片等不可编辑文件格式，乙方须确保内容清晰可辨，甲方不对原文的合法性、真实性以及是否侵权承担任何形式的责任， 因原文内</div>
        <div style="margin-left: 22px">容引起的一切纠纷由乙方承担，给甲方及其关联公司造成任何损失的，乙方应负责赔偿。</div>
      </div>
      <div class="proto-one"><span>6.</span><span>保密责任</span></div>
      <div class="proto-one-content">
        <div>1、双方对因签订和/或履行本协议获得的对方的商业机密负有保密责任，不得以任何形式、任何理由透露给任何第三方，但依据双方书面约定、依据法律法规，或者依据司 法
        </div>
        <div style="margin-left: 22px">和/或政府机关的要求进行披露的除外，否则，任何一方都有权向对方请求损失赔偿，并依法追究法律责任。</div>
        <div>2、保密责任不因本协议的无效、提前终止、解除或不具操作性而失效。</div>
      </div>
      <div class="proto-one"><span>7.</span><span>协议的修改和解释</span></div>
      <div class="proto-one-content">
        <div>1、甲方有权随时根据中华人民共和国有关法律、法规的变化以及公司经营状况和经营策略的调整等情况修改本协议，如发生相关争议，以该平台最新的《人工翻译平台服
          务协
        </div>
        <div style="margin-left: 22px">议》为准。</div>
        <div>2、如协议条款与中华人民共和国法律相抵触，该条款将完全依据法律规定重新解释，其它条款则继续保持对乙方产生法律效力和影响。</div>
      </div>
      <div class="proto-one"><span>8.</span><span>争议解决</span></div>
      <div class="proto-one-content">
        <div>1、本协议的生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</div>
        <div>2、因本协议引起的或与本协议有关的任何争议，由北京市西城区人民法院管辖。</div>
      </div>
      <div class="proto-one"><span>9.</span><span>生效</span></div>
      <div class="proto-one-content">
        <div>本协议经乙方提交翻译服务请求，生成订单并支付后生效。</div>
      </div>
      <div class="proto-one"><span>10.</span><span>特别说明</span></div>
      <div class="proto-one-content">
        <div>1、双方确认，本协议及其更新版本是甲乙双方签署的乙方享有《人工翻译平台服务协议》的最终确定版本。乙方勾选《人工翻译平台服务协议》请求，视为乙方同意并签署
        </div>
        <div style="margin-left: 22px"> 本协议。</div>
        <div>2、甲方郑重提示：为保证您享有高效优质的服务，请务必仔细阅读并确保完全理解《人工翻译平台服务协议》有关条款。如您对本服务条款存有任何疑问，可发邮件至
        </div>
        <div style="margin-left: 22px"><span style="color: #063F95;font-family: SourceHanSansCN-Regular">service@cipgtrans.com</span>
          获取相关信息。
        </div>
        <div>3、对于翻译服务过程中出现的任何问题：</div>
        <div style="margin-left: 14px;">（1）乙方直接联系甲方解决，客服电话：400-004-5131，邮箱：<span
            style="color: #063F95;font-family: SourceHanSansCN-Regular">service@cipgtrans.com</span></div>
        <div style="margin-left: 14px;">（2）如协商失败，可联系微思翻译服务平台：发送邮件至平台服务 <span
            style="color: #063F95;font-family: SourceHanSansCN-Regular">info@wiseivy.com.cn</span>、或者联系微思翻译
          <span style="color: #063F95;font-family: SourceHanSansCN-Regular">https://wesitrans.com/index</span>
          在线QQ、提交在线反馈、
        </div>
        <div style="margin-left: 22px">或直接拨打010-85804648，说明具体详细情况，平台会提供协助服务。
        </div>
        <div>4、由于翻译质量造成的用户损失，由乙方直接联系甲方，双方协商后解决，如协商失败可发送邮件或者联系微思翻译在线客服人员，并说明详细情况，平台会提供协助服务。</div>
      </div>
    </div>
    <div v-if="hasH5" class="main-price">
      <div class="main-price-head">
        <img @click="clickHandler('/')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
        <div>协议</div>
      </div>
      <div class="main-price-content">
        <div class="proto-content">
          <div class="proto-title">人工翻译平台服务协议</div>
          <div class="proto-one" style="margin-top: 40px"><span>1.</span><span>定义及解释</span></div>
          <div class="proto-one-content">
            <div>1、北京中外翻译咨询有限公司：以下简称“甲方”。</div>
            <div>2、用户：指符合本协议规定的条件，使用该人工翻译平台的单位或个人，以下简称“乙方”。</div>
            <div>3、翻译服务：指甲方按照本服务协议通过该人工翻译平台向乙方提供的专业翻译服务。翻译服务的所有权及运作权归甲方所有。</div>
            <div>4、翻译平台：为有专业翻译需求的用户提供人工翻译服务的媒介</div>
          </div>
          <div class="proto-one"><span>2.</span><span>协议的确认与接受</span></div>
          <div class="proto-one-content">
            <div>乙方理解并同意甲方在该平台上公布的《人工翻译平台服务协议》（即“本协议”）及其后续更新版本中的内容。乙方使用人工翻译平台专业翻译服务的行为将视为乙方对本协议全部内容和条款的接受。</div>
          </div>
          <div class="proto-one"><span>3.</span><span>人工翻译服务的内容</span></div>
          <div class="proto-one-content">
            <div>1、乙方通过人工翻译平台的相关页面，向甲方提交委托翻译服务请求。</div>
            <div>2、乙方提交翻译稿件：乙方通过人工翻译平台相关页面向甲方提交所需要翻译的稿件。</div>
            <div>3、字数统计：字数统计根据乙方提交翻译稿件使用 Microsoft Word “字数统计”功能确定，页眉、页脚、文本框、图片另行统计。</div>
            <div>4、服务费用：</div>
            <div style="margin-left: 20px;">a).专业翻译服务：甲方根据乙方对翻译内容用途、质量等级的要求，按照不同质量标准、不同语种进行收费。</div>
            <div> 收费标准如下：</div>
          </div>
          <div class="dialogClassMoney" style="margin-top: -16px;">
            <div class="dialogClass-content">
              <div>
                <img src="../assets/价格说明表2.jpg" alt="价格表" width="710" height="2755">
              </div>
            </div>
          </div>
          <div class="pginfo">
            <div style="margin-left: 92px">
              <div style="margin-top: 15px;margin-bottom: 18px;">温馨提示：</div>
              <div>
                <div style="margin-right: 75px;">1、原文是中文、日文、韩文，按照word文档字数统计里“字符数（不计空格）”计算价格。</div>
                <div style="margin-right: 75px;">2、原文是外文（除日文、韩文），按照外文单词数/字数计算价格。</div>
                <div>3、排版、打印、盖章等都是额外的服务，收费另计。</div>
                <div>4、不足300字按300字计价。</div>
                <div style="margin-left: 22px">b).英文母语润色</div>
                <div style="margin-left: 22px;margin-right: 75px">母语润色服务，对具有一定基础、可读性的英文稿件，提供在原文基础上进行专业母语润色、审校、质检服务。
                  由专业学科母语编辑提供稿件润色服务，改善语言质
                  量，提升表达效果，可助力SCI论文成功发表。甲方根据乙方稿件字数按照不同的服务级别进行收费，收费标准如下：基础润色399元/千词，高级润色699元/千词。
                </div>
                <div>5、交稿时间：</div>
                <div style="margin-left: 22px;margin-right: 75px">
                  a).专业翻译：对于2000字以下的标准级翻译稿件，一般情况下，甲方收到乙方服务费用后1个工作日内交稿；甲方有权根据稿件难易程度等因素调整交稿时间，并通
                  过双方协商确认。
                </div>
                <div style="margin-left: 22px;margin-right: 75px">
                  b).英文母语润色：基础润色处理速度为1000词/天，高级润色处理速度为750词/天。甲方有权根据稿件难易程度等因素调整交稿时间，并通过双方协商确认。
                </div>
                <div style="margin-right: 75px;">6、交稿方式：甲方将在人工翻译平台网站“历史订单”中返回译文，</div>
                <div style="margin-right: 75px">7、售后服务：甲方交稿3日后不支持退款，日常快译交稿后72小时内、专业翻译交稿后15日内提供免费修改服务、英文母语润色基础润色15日内提供免费
                  修改1次，高级润色90日内提供免费修改1次。由于翻译质量造成的问题及下错订单等情况，可与客服协商退款，其他情况仅支持免费修改。</div>
                <div style="margin-right: 75px">
                  8、修改须知：如交稿后，乙方对原文进行修改，则修改内容另行计费；修改文字超过20%，按照全文重新翻译计算。如在翻译过程中更换或修改原文，已经完成部分要
                </div>
                <div style="margin-left: 22px">计入稿费。
                </div>
              </div>
            </div>
          </div>
          <div class="proto-one" style="margin-top: 38px"><span>4.</span><span>付款及发票</span></div>
          <div class="proto-one-content">
            <div>1、乙方应按照甲方网站提示，在提交所需翻译的稿件之前通过翻译平台向甲方全额支付相关翻译费用。</div>
            <div>2、付款方式：乙方通过翻译平台提供的在线支付方式一次性支付所有翻译费用。具体付款方式以平台提供为准。</div>
            <div>3、发票：乙方支付款项对应的发票由甲方提供。</div>
          </div>
          <div class="proto-one"><span>5.</span><span>双方权利义务</span></div>
          <div class="proto-one-content">
            <div>1、甲方承诺按照中华人民共和国国家标准GB/T 19682-2005相关规定完成稿件翻译服务。</div>
            <div>2、翻译内容由乙方提供，如涉及图片、照片等不可编辑文件格式，乙方须确保内容清晰可辨，甲方不对原文的合法性、真实性以及是否侵权承担任何形式的责任， 因原文内</div>
            <div style="margin-left: 22px">容引起的一切纠纷由乙方承担，给甲方及其关联公司造成任何损失的，乙方应负责赔偿。</div>
          </div>
          <div class="proto-one"><span>6.</span><span>保密责任</span></div>
          <div class="proto-one-content">
            <div>1、双方对因签订和/或履行本协议获得的对方的商业机密负有保密责任，不得以任何形式、任何理由透露给任何第三方，但依据双方书面约定、依据法律法规，或者依据司 法
            </div>
            <div style="margin-left: 22px">和/或政府机关的要求进行披露的除外，否则，任何一方都有权向对方请求损失赔偿，并依法追究法律责任。</div>
            <div>2、保密责任不因本协议的无效、提前终止、解除或不具操作性而失效。</div>
          </div>
          <div class="proto-one"><span>7.</span><span>协议的修改和解释</span></div>
          <div class="proto-one-content">
            <div>1、甲方有权随时根据中华人民共和国有关法律、法规的变化以及公司经营状况和经营策略的调整等情况修改本协议，如发生相关争议，以该平台最新的《人工翻译平台服
              务协
            </div>
            <div style="margin-left: 22px">议》为准。</div>
            <div>2、如协议条款与中华人民共和国法律相抵触，该条款将完全依据法律规定重新解释，其它条款则继续保持对乙方产生法律效力和影响。</div>
          </div>
          <div class="proto-one"><span>8.</span><span>争议解决</span></div>
          <div class="proto-one-content">
            <div>1、本协议的生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。</div>
            <div>2、因本协议引起的或与本协议有关的任何争议，由北京市西城区人民法院管辖。</div>
          </div>
          <div class="proto-one"><span>9.</span><span>生效</span></div>
          <div class="proto-one-content">
            <div>本协议经乙方提交翻译服务请求，生成订单并支付后生效。</div>
          </div>
          <div class="proto-one"><span>10.</span><span>特别说明</span></div>
          <div class="proto-one-content">
            <div>1、双方确认，本协议及其更新版本是甲乙双方签署的乙方享有《人工翻译平台服务协议》的最终确定版本。乙方勾选《人工翻译平台服务协议》请求，视为乙方同意并签署
            </div>
            <div style="margin-left: 22px"> 本协议。</div>
            <div>2、甲方郑重提示：为保证您享有高效优质的服务，请务必仔细阅读并确保完全理解《人工翻译平台服务协议》有关条款。如您对本服务条款存有任何疑问，可发邮件至
            </div>
            <div style="margin-left: 22px"><span style="color: #063F95;font-family: SourceHanSansCN-Regular">service@cipgtrans.com</span>
              获取相关信息。
            </div>
            <div>3、对于翻译服务过程中出现的任何问题：</div>
            <div style="margin-left: 14px;">（1）乙方直接联系甲方解决，客服电话：400-004-5131，邮箱：<span
                style="color: #063F95;font-family: SourceHanSansCN-Regular">service@cipgtrans.com</span></div>
            <div style="margin-left: 14px;">（2）如协商失败，可联系微思翻译服务平台：发送邮件至平台服务 <span
                style="color: #063F95;font-family: SourceHanSansCN-Regular">info@wiseivy.com.cn</span>、或者联系微思翻译
              <span style="color: #063F95;font-family: SourceHanSansCN-Regular">https://wesitrans.com/index</span>
              在线QQ、提交在线反馈、
            </div>
            <div style="margin-left: 22px">或直接拨打010-85804648，说明具体详细情况，平台会提供协助服务。
            </div>
            <div>4、由于翻译质量造成的用户损失，由乙方直接联系甲方，双方协商后解决，如协商失败可发送邮件或者联系微思翻译在线客服人员，并说明详细情况，平台会提供协助服务。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Proto",
  data() {
    return {
      hasH5: false,
      tableData3: [
        {
          language: "英语",
          level: "标准级",
          zhto: "120",
          ento: "220"
        },
        {
          language: "英语",
          level: "应用级",
          zhto: "260",
          ento: "350"
        },
        {
          language: "英语",
          level: "母语级",
          zhto: "400",
          ento: "600"
        },
        {
          language: "英语",
          level: "专家级",
          zhto: "580",
          ento: "700"
        },
        {
          language: "俄语",
          level: "应用级",
          zhto: "260",
          ento: "400"
        },
        {
          language: "法语",
          level: "应用级",
          zhto: "260",
          ento: "380"
        },
        {
          language: "西班牙语",
          level: "应用级",
          zhto: "300",
          ento: "520"
        },
        {
          language: "葡萄牙语",
          level: "应用级",
          zhto: "300",
          ento: "520"
        },
        {
          language: "日语",
          level: "应用级",
          zhto: "240",
          ento: "240"
        },
        {
          language: "韩语",
          level: "应用级",
          zhto: "240",
          ento: "240"
        },
        {
          language: "阿拉伯语",
          level: "应用级",
          zhto: "380",
          ento: "550"
        },
        {
          language: "德语",
          level: "应用级",
          zhto: "300",
          ento: "450"
        },
        {
          language: "土耳其语",
          level: "应用级",
          zhto: "600",
          ento: "1000"
        },
        {
          language: "意大利语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "荷兰语",
          level: "应用级",
          zhto: "600",
          ento: "1000"
        },
        {
          language: "泰语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "越南语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "马来语",
          level: "应用级",
          zhto: "350",
          ento: "520"
        },
        {
          language: "柬埔寨语",
          level: "应用级",
          zhto: "600",
          ento: "1000"
        },
        {
          language: "老挝语",
          level: "应用级",
          zhto: "400",
          ento: "600"
        },
      ],
      tableData4: [
        {
          language: "俄语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "法语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "西班牙语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "葡萄牙语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "日语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "韩语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "阿拉伯语",
          level: "母语级",
          zhto: "600",
        },
        {
          language: "德语",
          level: "母语级",
          zhto: "500",
        },
        {
          language: "土耳其语",
          level: "母语级",
          zhto: "1000",
        },
        {
          language: "意大利语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "荷兰语",
          level: "母语级",
          zhto: "1000",
        },
        {
          language: "泰语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "越南语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "马来语",
          level: "母语级",
          zhto: "650",
        },
        {
          language: "柬埔寨语",
          level: "母语级",
          zhto: "1000",
        },
        {
          language: "老挝语",
          level: "母语级",
          zhto: "700",
        },],
    }
  },
  created() {
    this.hasH5 = this.isMobile()
  },
  methods: {
    isMobile() {
      return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
    },
    clickHandler(url) {
      this.$router.push(url)
    },
    objectMuYuMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 16,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    objectZhuanYeMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (rowIndex < 4) {
          return {
            rowspan: 1,
            colspan: 1,
          }
        }
        if (rowIndex === 4) {
          return {
            rowspan: 16,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
      if (columnIndex === 1) {
        if (rowIndex > 3) {
          return {
            rowspan: 1,
            colspan: 1,
          }
        }
        if (rowIndex === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    objectMuYuClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1 || columnIndex === 2) {
        if (rowIndex === 15) {
          return "cell-big"
        }
      }
    },
  }
}
</script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
<style lang="less" scoped>
.main-price {
  min-width: 765px;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;

  .main-price-head {
    width: 100%;
    height: 88px;
    background: #E6E6E6;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;

    div {
      flex: 1;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-right: 60px;
    }
  }

  .main-price-content {
    width: 768px;
    .proto-content {
      width: 768px;
      height: 7000px;
      background: #FFFFFF;
      font-size: 30px;

      .proto-title {
        height: 24px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 56px;
      }

      .proto-one {
        text-align: left;
        margin-left: 31px;
        display: flex;
        align-items: center;

        span:nth-child(1) {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #666666;
          margin-right: 17px;
        }

        span:nth-child(2) {
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }

      .proto-one-content {
        text-align: left;
        margin-left: 66px;
        margin-top: 5px;
        display: flex;
        flex-flow: column;
        margin-bottom: 35px;
        margin-right: 57px;

        div {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
      }

      /deep/ .dialogClassMoney {
        /deep/ .el-table {
          --el-table-border-color: #ffffff;
          --el-table-border: 1px solid #F5F5F5;
          --el-table-font-color: let(--el-text-color-regular);
          --el-table-header-font-color: let(--el-text-color-secondary);
          --el-table-row-hover-background-color: let(--el-background-color-base);
          --el-table-current-row-background-color: let(--el-color-primary-light-9);
          --el-table-header-background-color: let(--el-color-white);
          --el-table-fixed-box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
          --el-table-background-color: let(--el-color-white);
          --el-table-expanded-cell-background-color: let(--el-color-white);

          .el-table__header {
            border-left: 2px solid #F5F5F5;
          }
        }

        /deep/ .el-dialog__body {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: column;
        }


        .money-desc {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          margin-left: 82px;
          margin-bottom: 10px;
          margin-top: -10px;
          width: 1140px;
        }

        .dialogClass-content {
          display: flex;
          align-items: center;
          justify-content: center;

          .cell-big {
            height: 240px;
            vertical-align: baseline;
          }

          /deep/ .cell {
            font-size: 24px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #333333;
            text-align: center;
          }

          .dialog-tips2 {
            width: 422px;
            height: 46px;
            background: #D3DEE8;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 2px;

            span {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }

          .dialog-tips {
            width: 615px;
            height: 46px;
            background: #D3DEE8;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 2px;

            span {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }

          .dialog-tip-header {
            width: 520px;
            height: 40px;
            background: #F5F5F5;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            border-left: 2px solid #F5F5F5;

            .is-leaf {
              background: #F5F5F5;
              text-align: center;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }
        }

      }

      .pginfo {
        text-align: left;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
    }
  }
}

.proto-content {
  width: 1201px;
  height: 3650px;
  background: #FFFFFF;

  .proto-title {
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 56px;
  }

  .proto-one {
    text-align: left;
    margin-left: 31px;
    display: flex;
    align-items: center;

    span:nth-child(1) {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #666666;
      margin-right: 17px;
    }

    span:nth-child(2) {
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }

  .proto-one-content {
    text-align: left;
    margin-left: 66px;
    margin-top: 5px;
    display: flex;
    flex-flow: column;
    margin-bottom: 35px;
    margin-right: 57px;

    div {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
    }
  }

  /deep/ .dialogClassMoney {
    /deep/ .el-table {
      --el-table-border-color: #ffffff;
      --el-table-border: 1px solid #F5F5F5;
      --el-table-font-color: let(--el-text-color-regular);
      --el-table-header-font-color: let(--el-text-color-secondary);
      --el-table-row-hover-background-color: let(--el-background-color-base);
      --el-table-current-row-background-color: let(--el-color-primary-light-9);
      --el-table-header-background-color: let(--el-color-white);
      --el-table-fixed-box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      --el-table-background-color: let(--el-color-white);
      --el-table-expanded-cell-background-color: let(--el-color-white);

      .el-table__header {
        border-left: 2px solid #F5F5F5;
      }
    }

    /deep/ .el-dialog__body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
    }


    .money-desc {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      margin-left: 82px;
      margin-bottom: 10px;
      margin-top: -10px;
      width: 1140px;
    }

    .dialogClass-content {
      display: flex;
      align-items: center;
      justify-content: center;

      .cell-big {
        height: 240px;
        vertical-align: baseline;
      }

      /deep/ .cell {
        font-size: 24px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
        text-align: center;
      }

      .dialog-tips2 {
        width: 422px;
        height: 46px;
        background: #D3DEE8;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2px;

        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }

      .dialog-tips {
        width: 615px;
        height: 46px;
        background: #D3DEE8;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2px;

        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }

      .dialog-tip-header {
        width: 520px;
        height: 40px;
        background: #F5F5F5;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        border-left: 2px solid #F5F5F5;

        .is-leaf {
          background: #F5F5F5;
          text-align: center;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }

  }

  .pginfo {
    text-align: left;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
}
</style>